import {
  CATALOG_REQUEST,
  CATALOG_REQUEST_SUCCESS,
  CATALOG_REQUEST_FAILED,
  CatalogRequestSuccess,
  ErrorMessage,
} from '../types/catalog';

export const catalogRequest = () => ({
  type: CATALOG_REQUEST,
} as const);

export const catalogRequestFailed = (payload: ErrorMessage) => ({
  type: CATALOG_REQUEST_FAILED,
  payload,
} as const);

export const catalogRequestSuccess = (payload: CatalogRequestSuccess) => ({
  type: CATALOG_REQUEST_SUCCESS,
  payload,
} as const);

export type CatalogActionsTypes =
  | ReturnType<typeof catalogRequest>
  | ReturnType<typeof catalogRequestFailed>
  | ReturnType<typeof catalogRequestSuccess>;

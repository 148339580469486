import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Sidebar from './Sidebar';
import styles from './styles';
import { selectAuthData } from '../../redux/selectors/auth';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({ userRole: selectAuthData(state).isPM ? 'PM' : 'USER' });

export default withStyles(styles)(connect(mapStateToProps)(Sidebar));

import React, { useEffect } from 'react';
import { WithStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProfileForm from '../../components/ProfileForm';

import styles from './styles';
import { AgentDetails } from '../../redux/types/agents';
import { UserData } from '../../redux/selectors/auth';

type Props = {
  user: UserData;
  agentSelected: null | AgentDetails;
  loadAgentById: (username: string) => void;
  updateAgentById: (payload: Record<string, any>, id: string | number) => void;
} & WithStyles<typeof styles>;

const EditProfile: React.FC<Props> = props => {
  const {
    classes, loadAgentById, user, agentSelected, updateAgentById,
  } = props;

  useEffect(() => {
    loadAgentById(user.username);
  }, [user.username, loadAgentById]);

  function handleSave(agentPayload: Record<string, any>): void {
    updateAgentById(agentPayload, user.username);
  }

  if (!agentSelected) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress size={48} className={classes.progress} />
      </div>
    );
  }

  return (
    <ProfileForm user={agentSelected} onSave={handleSave} />
  );
};

export default React.memo(EditProfile);

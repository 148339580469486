import Dashboard from '../containers/Dashboard';
import Inventory from '../containers/Inventory';
// import LockDetails from '../containers/LockDetails';
import Properties from '../containers/Properties';
import PropertyDetails from '../containers/PropertyDetails';
import ProfitCenter from '../containers/ProfitCenter';
import Insights from '../containers/Insights';
import WorkBench from '../containers/WorkBench';
import Customers from '../containers/Customers';
import CustomerDetails from '../containers/CustomerDetails';
import Visits from '../containers/Visits';
import Account from '../containers/Account';
import Integrations from '../containers/Integrations';
import Agents from '../containers/Agents';
import AgentForm from '../containers/AgentForm';
import InvoiceDetails from '../containers/InvoiceDetails';

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/account/billing/invoices/:id', name: 'Account', component: InvoiceDetails },
  { path: '/account/billing/paid/:id', name: 'Account', component: InvoiceDetails },
  { path: '/account/billing/past-due/:id', name: 'Account', component: InvoiceDetails },
  // commented out, bsc we don't want to show this yet
  // { path: '/inventory/:lockSerial', name: 'LockDetails', component: LockDetails },
  { path: '/inventory', name: 'Inventory', component: Inventory },
  { path: '/visits', name: 'Visits', component: Visits },
  { path: '/properties/:propertyId', name: 'PropertyDetails', component: PropertyDetails },
  { path: '/properties', name: 'Properties', component: Properties },
  { path: '/profit-center', name: 'Profit Center', component: ProfitCenter },
  { path: '/integrations', name: 'Integrations', component: Integrations },
  { path: '/customers/:customerId', name: 'CustomerDetails', component: CustomerDetails },
  { path: '/customers', name: 'Customers', component: Customers },
  { path: '/insights', name: 'Insights', component: Insights },
  { path: '/workbench', name: 'WorkBench', component: WorkBench },
  { path: '/account', name: 'Account', component: Account },
  { path: '/agents/add', name: 'AgentForm', component: AgentForm },
  {
    path: '/agents', name: 'Agents', component: Agents, roles: ['PM'],
  },
];

export default routes;

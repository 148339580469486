import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  drawerContent: {
    padding: '30px 40px 30px 30px',
  },
  avatar: {
    width: 81,
    height: 81,
    backgroundColor: theme.appPalette.orange,
    fontSize: 32,
    color: '#fff',
  },
  userInfo: {
    fontSize: 32,
    textAlign: 'center',
  },
  iconClose: {
    padding: 3,
    backgroundColor: '#8E8B99',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'none',
      color: theme.appPalette.orange,
    },
  },
  icon: {
    width: 29,
    height: 20,
    marginBottom: '-3px',
    marginRight: 8,
  },
  row: {
    marginBottom: 16,
  },
  userProfileLink: {
    fontSize: 18,
    textAlign: 'center',
    color: theme.appPalette.orange,
  },
  paperBase: {
    marginTop: 8,
    borderRadius: 5,
    boxShadow: 'none',
    display: 'inline-block',
    color: '#fff',
    fontSize: 18,
  },
  statusPaper: {
    padding: '9px 23px',
    backgroundColor: '#04A777',
  },
  typePaper: {
    padding: '9px 27px',
    backgroundColor: '#040620',
    textTransform: 'uppercase',
  },
  tabsRoot: {
    backgroundColor: theme.appPalette.orange,
    '& button.MuiTab-root': {
      fontSize: 24,
      fontWeight: 'normal',
      textTransform: 'capitalize',
      color: '#fff',
      '&.Mui-selected': {
        backgroundColor: '#fff',
        color: '#8e8b99',
      },
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: theme.appPalette.orange,
      top: 0,
    },
  },
  tabContainer: {
    '& > div': {
      marginBottom: 16,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
  tabTitle: {
    fontSize: 20,
    color: '#000',
  },
  email: {
    color: theme.appPalette.orange,
  },
});

import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { MimeType } from '../redux/types/mime';
import { PropertyDetailsAgent } from '../redux/types/properties';

export const loadProperties = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/properties${query}`);
};

export const loadPropertyById = (id: number | string) => axios.get(`/properties/${id}`);

export const propertyAddAgent = (payload: { propertyId: number | string, agentId: string }) => axios.patch<PropertyDetailsAgent>(`/properties/${payload.propertyId}/agent`, { agentUsername: payload.agentId });

export const propertyRemoveAgent = (payload: { propertyId: number | string, agentId: string }) => axios.delete<{message: string}>(`/properties/${payload.propertyId}/agent`, { data: { agentUsername: payload.agentId } });

export const loadPropertiesExport = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/properties/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

import { createStyles } from '@material-ui/core';

export default () => createStyles({
  rootList: {
    maxWidth: 900,
    width: '100%',
  },
  headerList: {
    display: 'flex',
    padding: '12px 30px 12px 20px',
    marginBottom: 8,
  },
  headerActivitiesWrapper: {
    display: 'flex',
    '&, & > div': {
      flexGrow: 1,
    },
  },
  leftSection: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-between',
  },
});

/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../../components/SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { formatDate } from '../../utils/date';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Order } from '../../utils/table';
import { Visit } from '../../redux/types/visits';
import { Query } from '../../utils/query';

type Props = {
  loading: boolean;
  visits: Array<Visit>;
  visitsTotal: number;
  loadVisits: (query?: Query) => void;
  exportVisits: (selection: Array<number | string>) => void;
  printVisits: (selection: Array<number | string>) => void;
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all visits';

const headRows: Array<HeaderRowConfig<Visit>> = [
  {
    key: 'firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'cell_phone_1',
    align: 'right',
    disablePadding: false,
    label: 'Cell',
  },
  {
    key: 'visitor_type_id',
    align: 'center',
    disablePadding: true,
    label: 'Type',
  },
  {
    key: 'visitor_status_id',
    align: 'center',
    disablePadding: true,
    label: 'Status',
    render: visit => {
      const cellStyles = {
        color: visit.visitor_status_id.toLowerCase() === 'accessed' ? '#04A777' : 'inherit',
      };
      return <TableCell style={cellStyles} align="right">{visit.visitor_status_id}</TableCell>;
    },
  },
  {
    key: 'subdivision',
    align: 'left',
    disablePadding: false,
    label: 'Subdivision',
  },
  {
    key: 'address_1',
    align: 'right',
    disablePadding: false,
    label: 'Address',
    prepareData: ({ address_1, house_num }) => ([house_num, address_1].filter(Boolean).join(' ')),
  },
  {
    key: 'platform',
    align: 'center',
    disablePadding: true,
    label: 'Platform',
  },
  {
    key: 'modified_on',
    align: 'right',
    disablePadding: true,
    label: 'Visit Date',
    prepareData: ({ modified_on }) => formatDate(modified_on),
  },
];

const Visits: React.FC<Props> = props => {
  const {
    classes, visits, visitsTotal, loadVisits, loading, exportVisits, printVisits, history,
  } = props;
  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Visit>('modified_on');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE);
  const [selected, setSelected] = useState<Array<Visit['_id']>>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    loadVisits({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, loadVisits, search]);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  function handleCustomerVisitSelect(visit: Visit) {
    history.push(`/customers/${visit.customer_id}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportVisits(selected);
    }
  }

  function handlePrint() {
    if (selectedLength !== 0) {
      printVisits(selected);
    }
  }

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Visit) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  function handleSelect(newSelected: Array<any>) {
    setSelected(newSelected);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder={searchBarPlaceholder}
            onChange={setSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Visits
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button size="small">Delete</Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Visit>
            className={classes.table}
            IDKey="_id"
            selected={selected}
            data={visits}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleCustomerVisitSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={visits.length}
          rowsTotal={visitsTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Visits);

import React from 'react';

import { WithStyles } from '@material-ui/styles';
import styles from './styles';

type Props = WithStyles<typeof styles>;

const List: React.FC<Props> = props => {
  const { classes, children } = props;

  return (
    <div className={classes.rootList}>
      <div className={classes.headerList}>
        <div className={classes.leftSection}>Name</div>
        <div className={classes.headerActivitiesWrapper}>
          <div>Type</div>
          <div>Status</div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default React.memo(List);

import { WithStyles } from '@material-ui/styles';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import styles from './styles';

type Props = {
  onClose: () => void;
} & WithStyles<typeof styles>;

const DrawerAddIntegration: React.FC<Props> = props => {
  const { classes, onClose } = props;

  return (
    <div className={classes.drawerContent}>
      <Grid container justify="flex-end">
        <Grid item xs={1}>
          <IconButton className={classes.iconClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container className={classes.row}>
        <form>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.formTitle} variant="h4">
                Add Integration
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                select
                variant="filled"
                name="integration"
                label="Select integration"
              >
                {[
                  { value: 'integration1', label: 'integration 1' },
                  { value: 'integration2', label: 'integration 2' },
                  { value: 'integration3', label: 'integration 3' },
                ].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="filled"
                fullWidth
                multiline
                rows={5}
                rowsMax={6}
                name="integrationApiCode"
                label="Integration API Code"
                defaultValue="Paste API Code here."
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">
                Activate

                <Switch />
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.cardActions}>
            <Button
              type="submit"
              variant="contained"
              className={classnames(classes.button, classes.saveButton)}
            >
              Add
            </Button>
          </div>
        </form>
      </Grid>
    </div>
  );
};

export default React.memo(DrawerAddIntegration);

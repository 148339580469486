import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InvoicesTable from '../../components/InvoicesTable';
import BillingToolBar from '../BillingToolBar';
import { Invoice } from '../../redux/types/billing';
import TablePagination from '../../components/CustomTablePagination';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE } from '../../constants/table';

type Props = {
  invoicesDetails: Array<Invoice>;
  totalInvoiceAmount: number,
  downloadFakePdf: () => void
} & RouteComponentProps;

const PaidInvoices: React.FC<Props> = props => {
  const {
    invoicesDetails, totalInvoiceAmount, downloadFakePdf, match, history,
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE);
  const [page, setPage] = React.useState(0);

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  function handleInvoiceSelect(invoice: Invoice) {
    history.push(`${match.url}/${invoice.id}`);
  }

  return (
    <>
      <BillingToolBar />
      <InvoicesTable
        invoices={invoicesDetails.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)}
        downloadFakePdf={downloadFakePdf}
        handleSingleInvoice={handleInvoiceSelect}
      />
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        rowsPerPage={rowsPerPage}
        currentPage={page}
        rowsCount={invoicesDetails.length}
        rowsTotal={totalInvoiceAmount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default React.memo(PaidInvoices);

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CustomerInfoCard from '../../components/CustomerInfoCard';
import CustomerInfoWorkbench from '../../components/CustomerInfoWorkbench';
import { CustomerDetails } from '../../redux/types/customers';

import styles from './styles';

type Props = {
  clearSelected: () => void;
  loadCustomerDetails: (customerId: number) => void;
  customerDetails: CustomerDetails | null;
  loading?: boolean;
} & WithStyles<typeof styles> & RouteComponentProps<{ customerId: string }>;

const CustomerDetailsPage: React.FC<Props> = props => {
  const {
    classes,
    loadCustomerDetails,
    clearSelected,
    customerDetails,
    match,
    history,
  } = props;

  function goBack() {
    history.goBack();
  }
  useEffect(() => {
    loadCustomerDetails(Number(match.params.customerId));
    return clearSelected;
  }, [loadCustomerDetails, clearSelected, match.params.customerId]);

  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            onClick={goBack}
          >
            <ArrowLeftIcon />
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
          >
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        {customerDetails && (
          <>
            <div className={classes.infoWrapper}>
              <CustomerInfoCard customerInfo={customerDetails.info} />
            </div>
            <div className={classes.workbenchWrapper}>
              <CustomerInfoWorkbench
                customerActivity={customerDetails.visits}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(CustomerDetailsPage);

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import WorkBench from './WorkBench';
import styles from './styles';
import { selectCustomers, selectCustomerSelected, selectCustomersRequestStatus } from '../../redux/selectors/customers';
import { customerDetailsRequest, customersRequest } from '../../redux/actions/customers';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  customer: selectCustomerSelected(state),
  customers: selectCustomers(state),
  customersLoading: selectCustomersRequestStatus(state),
});

const mapDispatchToProps = {
  loadCustomers: customersRequest,
  loadCustomerDetails: customerDetailsRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WorkBench),
);

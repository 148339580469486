import { WithStyles } from '@material-ui/styles';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button';
import styles from './styles';

type Props = {
  onClose: () => void;
} & WithStyles<typeof styles>;

const DrawerCreateApi: React.FC<Props> = props => {
  const { classes, onClose } = props;

  return (
    <div className={classes.drawerContent}>
      <Grid container justify="flex-end">
        <Grid item xs={1}>
          <IconButton className={classes.iconClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container className={classes.row}>
        <form>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.formTitle} variant="h4">
                Create Api
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="filled"
                fullWidth
                name="url"
                label="Paste URL"
                defaultValue="http://api/v1.test"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="filled"
                fullWidth
                multiline
                rows={5}
                rowsMax={6}
                name="description"
                label="Description"
                defaultValue="Description"
              />
            </Grid>
          </Grid>

          <div className={classes.cardActions}>
            <Button
              type="submit"
              variant="contained"
              className={classnames(classes.button, classes.saveButton)}
            >
              Add
            </Button>
          </div>
        </form>
      </Grid>
    </div>
  );
};

export default React.memo(DrawerCreateApi);

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LogOutIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import Notifications from '@material-ui/icons/Notifications';

import { Link } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import FeedbackDialog from '../FeedbackDialog';

import styles from './styles';
import { UserData } from '../../redux/selectors/auth';

type Props = {
  onLogOut: () => void;
  user: UserData;
} & WithStyles<typeof styles>;

const UserToolbar: React.FC<Props> = props => {
  const { classes, onLogOut, user } = props;
  const greetMessage = `Welcome, ${user.firstName} ${user.lastName}`;


  return (
    <Toolbar classes={{ root: classes.root }}>
      <div className={classes.spacer} />
      <div className={classes.userGreeting}>
        <Typography variant="h5">{greetMessage}</Typography>
      </div>
      <FeedbackDialog />
      <div className={classes.iconsContainer}>
        <IconButton component={Link} to="/account">
          <AccountCircleRounded fontSize="large" />
        </IconButton>
        <IconButton>
          <Notifications className={classes.notificationIcon} fontSize="large" />
        </IconButton>
      </div>
      <div>
        <IconButton onClick={onLogOut}>
          <LogOutIcon fontSize="large" />
        </IconButton>
      </div>

    </Toolbar>
  );
};

export default React.memo(UserToolbar);

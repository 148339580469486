import { createSelector } from 'reselect';
import { AppState } from '../store';
import { VisitsState } from '../types/visits';

export const selectVisitsState = (state: AppState) => state.visits;
const selectItems = (state: VisitsState) => state.items;
const selectLoading = (state: VisitsState) => state.loading;
const selectTotal = (state: VisitsState) => state.total;

export const selectVisits = createSelector(
  selectVisitsState,
  selectItems,
);

export const selectTotalVisits = createSelector(
  selectVisitsState,
  selectTotal,
);

export const selectVisitsRequestStatus = createSelector(
  selectVisitsState,
  selectLoading,
);

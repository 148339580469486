// eslint-disable-next-line import/prefer-default-export
export const convertToUsdCurrencyFormat = (price: number | string, prefix: string = 'US$', step: number = 3): string | number => {
  const num = Number(price);
  const isValid = num >= 0 && !Number.isNaN(num);

  if (!isValid) {
    return price;
  }

  const priceString = num.toFixed(2);
  const [wholeNumber, decimal] = priceString.split('.');
  const parsedWholeNumber = wholeNumber
    .split('')
    .reverse()
    .map((n, i, arr) => ((i + 1) % step > 0 || i === arr.length - 1 ? n : `,${n}`))
    .reverse()
    .join('');

  return `${prefix}${parsedWholeNumber}.${decimal}`;
};

export const devideDollarsFromCoins = (price: number | string) : string | number => {
  const num = Number(price);

  const rest = (num / 100).toFixed(2);
  return rest;
};

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  agentsRequest,
} from '../../redux/actions/agents';
import {
  selectAgents,
  selectTotalAgents,
  selectAgentsRequestStatus,
} from '../../redux/selectors/agents';
import Agents from './Agents';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  agents: selectAgents(state),
  agentsTotal: selectTotalAgents(state),
  loading: selectAgentsRequestStatus(state),
});

const mapDispatchToProps = {
  loadAgents: agentsRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Agents),
);

import { createMuiTheme, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const orange = {
  main: '#DB5C0E',
  light: '#F4D18C',
};

const theme: Theme = createMuiTheme({
  palette: {
    text: {
      primary: 'rgba(4, 6, 32, 1)',
      secondary: 'rgba(151, 151, 151, 1)',
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  appPalette: {
    appBackgroundColor: '#F4F6F9',
    orange: orange.main,
  },
  overrides: {

    MuiButton: {
      root: {
        textTransform: 'unset',
        color: orange.main,
        '&:hover': {
          backgroundColor: fade(orange.main, 0.05),
        },
      },
      contained: {
        backgroundColor: '#fff',
        color: orange.main,
      },
      containedPrimary: {
        backgroundColor: orange.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: fade(orange.main, 0.45),
        },
      },
    },

    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: orange.main,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: orange.main,
        },
        '& .MuiFilledInput-root': {
          '&:-internal-autofill-selected ': {
            backgroundColor: orange.main,
          },
        },
        '& .MuiFilledInput-underline:after': {
          borderBottomColor: orange.main,
        },
      },
    },

    MuiCircularProgress: {
      colorPrimary: {
        color: orange.main,
      },
    },

    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: orange.light,
      },
      barColorPrimary: {
        backgroundColor: orange.main,
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: orange.main,
        },
      },
    },

    MuiSwitch: {
      colorSecondary: {
        '&.Mui-checked': {
          color: orange.main,
          '&&:hover': {
            backgroundColor: fade(orange.main, 0.08),
          },
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: orange.main,
        },
      },
    },
  },
});

export default theme;

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import * as storage from './storage';
import appConfig from '../config';
import { AUTH_HEADER, AUTH_TOKEN } from '../constants/auth';

export const headers = {
  'Content-Type': 'application/json',
};
const config: AxiosRequestConfig = {
  headers,
  baseURL: appConfig.API_ROOT,
};
const instance = axios.create(config);

const token = storage.getItem(AUTH_TOKEN);
if (token) {
  instance.defaults.headers.common[AUTH_HEADER] = token;
}

const onFulfilled = (response: AxiosResponse): AxiosResponse => response;
const onRejected = (error: AxiosError): Promise<AxiosError> => {
  const codes: Array<number> = [401];

  if (error.response && codes.includes(error.response.status)) {
    storage.clear();
    window.location.reload();
  }

  return Promise.reject(error);
};

instance.interceptors.response.use(onFulfilled, onRejected);

export default instance;

import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { WithStyles } from '@material-ui/styles';
import { SEARCH_INPUT_DELAY } from '../../constants/input';

import styles from './styles';

function noOP(): void{}

type Props = {
  onChange?: (search: string) => void;
  placeholder?: string;
} & WithStyles<typeof styles>;

const SearchBar: React.FC<Props> = props => {
  const { classes, onChange = noOP, placeholder } = props;

  const debounced = useCallback(debounce(onChange, SEARCH_INPUT_DELAY), [onChange]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    debounced(e.target.value);
  }

  return (
    <OutlinedInput
      fullWidth
      labelWidth={0}
      name="searchBar"
      autoComplete="off"
      placeholder={placeholder}
      classes={{ root: classes.searchBarRoot }}
      onChange={handleChange}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon className={classes.searchBarIcon} />
        </InputAdornment>
)}
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
};

export default React.memo(SearchBar);

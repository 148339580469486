/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Spinner from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { RouteComponentProps } from 'react-router-dom';
import TablePagination from '../../components/CustomTablePagination';
import SearchBar from '../../components/SearchBar';
import Table from '../../components/CustomTable';
import { formatDate } from '../../utils/date';

import styles from './styles';
import { Property } from '../../redux/types/properties';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Query } from '../../utils/query';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../constants/table';

type Props = {
  properties: Array<Property>;
  propertiesTotal: number;
  loading: boolean;
  loadProperties: (query: Query) => void;
  exportProperties: (selection: Array<number | string>) => void;
  printProperties: (selection: Array<number | string>) => void;
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all properties';
const statusColor: Record<string, string> = {
  active: '#04A777',
  down: '#D72638',
};

const headRows: Array<HeaderRowConfig<Property>> = [
  {
    key: 'property_id',
    align: 'right',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    key: 'subdivision',
    align: 'right',
    disablePadding: false,
    label: 'Subdivision',
  },
  {
    key: 'lock_serial_number',
    align: 'right',
    disablePadding: false,
    label: 'Lock#',
  },
  {
    key: 'lock_status',
    align: 'right',
    disablePadding: true,
    label: 'Lock Status',
    render: property => {
      let cellStyles;
      if (property.lock_status) {
        cellStyles = { color: statusColor[property.lock_status.toLowerCase()] || 'inherit' };
      }
      return <TableCell style={cellStyles} align="right">{property.lock_status}</TableCell>;
    },
  },
  {
    key: 'disposition_id_value',
    align: 'right',
    disablePadding: true,
    label: 'Lock Disposition',
  },
  {
    key: 'address_1',
    align: 'right',
    disablePadding: false,
    label: 'Address',
    prepareData: ({ address_1, house_num }) => ([house_num, address_1].filter(Boolean).join(' ')),
  },
  {
    key: 'lock_assigned',
    align: 'right',
    disablePadding: false,
    label: 'Install Date',
    prepareData: ({ lock_assigned }) => formatDate(lock_assigned),
  },
  {
    key: 'lock_terminated',
    align: 'right',
    disablePadding: false,
    label: 'Removal Date',
    prepareData: ({ lock_terminated }) => formatDate(lock_terminated),
  },
];

const Properties: React.FC<Props> = props => {
  const {
    classes,
    properties,
    propertiesTotal,
    loading,
    loadProperties,
    exportProperties,
    printProperties,
    history,
    match,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Property>('disposition_id_value');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE);
  const [selected, setSelected] = useState<Array<Property['property_id']>>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    loadProperties({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, loadProperties, search]);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Property) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  function handleSelect(newSelected: Array<any>) {
    setSelected(newSelected);
  }

  function handlePropertySelect(property: Property) {
    history.push(`${match.url}/${property.property_id}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportProperties(selected);
    }
  }

  function handlePrint() {
    if (selectedLength !== 0) {
      printProperties(selected);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder={searchBarPlaceholder}
            onChange={setSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Properties
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button size="small">Delete</Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Property>
            hover
            className={classes.table}
            IDKey="property_id"
            selected={selected}
            data={properties}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handlePropertySelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={properties.length}
          rowsTotal={propertiesTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Properties);

import { combineReducers } from 'redux';
import auth from './auth';
import billing from './billing';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import locks from './locks';
import progressBarStatus from './progressBarStatus';
import stats from './stats';
import agents from './agents';
import snackbars from './snackbars';
import appResources from './appResources';
import split from './split';

export default combineReducers({
  auth,
  billing,
  locks,
  visits,
  customers,
  properties,
  progressBarStatus,
  stats,
  agents,
  snackbars,
  appResources,
  split,
});

import React, { useEffect } from 'react';
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Button,
} from '@material-ui/core';
// import useStyles from './styles';
import {
  getOauthClientsRequest as GetOauthClientsRequest,
  removeOauthClientsRequest as RemoveOauthClientsRequest,
  createOauthClientsRequest as CreateOauthClientsRequest,
} from '../../redux/actions/auth';
import { AuthState } from '../../redux/types/auth';

type Props = {
  getOauthClientsRequest: typeof GetOauthClientsRequest,
  removeOauthClientsRequest: typeof RemoveOauthClientsRequest,
  createOauthClientsRequest: typeof CreateOauthClientsRequest,
  oauthClients: AuthState['oauthClients'],
};

const EditProfile: React.FC<Props> = ({
  getOauthClientsRequest, oauthClients, createOauthClientsRequest, removeOauthClientsRequest,
}) => {
  // const classes = useStyles();

  const removeClient = (id: number) => () => {
    removeOauthClientsRequest(id);
  };

  const createClient = () => {
    createOauthClientsRequest();
  };

  useEffect(() => {
    getOauthClientsRequest();
  }, [getOauthClientsRequest]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={createClient} style={{ marginBottom: 25 }}> Add Client</Button>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client ID</TableCell>
              <TableCell align="right">Client Secret</TableCell>
              <TableCell align="right">Created AT</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oauthClients.map(client => (
              <TableRow key={client.id}>
                <TableCell component="th" scope="row">
                  {client.client_id}
                </TableCell>
                <TableCell align="right">{client.client_secret}</TableCell>
                <TableCell align="right">
                  {new Date(client.created_at).toDateString()}

                  -

                  {new Date(client.created_at).toLocaleTimeString()}
                </TableCell>
                <TableCell align="right">

                  <Button variant="contained" color="secondary" onClick={removeClient(client.id)}>Remove</Button>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default React.memo(EditProfile);

import React from 'react';
import {
  Drawer, List, WithStyles, Grid, Hidden,
} from '@material-ui/core';

// commented it out, accordingly to the Barrett comment
// import { ReactComponent as ProfitCenterIcon } from "../../assets/profitCenterIcon.svg";
// import { ReactComponent as InsightsIcon } from "../../assets/insightsIcon.svg";
import { ReactComponent as Logo } from '../../assets/menu.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboardIcon.svg';
import { ReactComponent as InventoryIcon } from '../../assets/inventoryIcon.svg';
import { ReactComponent as VisitsIcon } from '../../assets/visitsSideBarIcon.svg';
import { ReactComponent as CustomersIcon } from '../../assets/customersIcon.svg';
import { ReactComponent as PropertiesIcon } from '../../assets/propertiesIcon.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/integrationsIcon.svg';
import { ReactComponent as WorkbenchIcon } from '../../assets/workbenchIcon.svg';
import { ReactComponent as AccountIcon } from '../../assets/accountIcon.svg';
import SmallLogoIcon from '../Icons/SmallLogoIcon';
import MenuItem from './MenuItem';

import styles from './styles';

type Props = {
  userRole: string;
} & WithStyles<typeof styles>;

type SideBarMenuItem = {
  label: string;
  icon: React.ElementType;
  path: string;
  roles?: Array<string>;
};

// commented out, bsc we don't want to show this yet
const menuItems: SideBarMenuItem[] = [
  { label: 'Dashboard', icon: DashboardIcon, path: '/dashboard' },
  { label: 'Inventory', icon: InventoryIcon, path: '/inventory' },
  { label: 'Visits', icon: VisitsIcon, path: '/visits' },
  { label: 'Customers', icon: CustomersIcon, path: '/customers' },
  { label: 'Properties', icon: PropertiesIcon, path: '/properties' },
  // { label: "Profit Center", icon: ProfitCenterIcon, path: "/profit-center" },
  { label: 'Integrations', icon: IntegrationIcon, path: '/integrations' },
  // { label: "Insights", icon: InsightsIcon, path: "/insights" },
  { label: 'Workbench', icon: WorkbenchIcon, path: '/workbench' },
  // { label: "Account", icon: AccountIcon, path: "/account" }
  {
    label: 'Agents', icon: AccountIcon, path: '/agents', roles: ['PM'],
  },
];

const Sidebar: React.FC<Props> = ({ classes, userRole }) => (
  <Drawer
    className={classes.drawer}
    variant="permanent"
    classes={{
      paper: classes.drawerPaper,
    }}
    anchor="left"
  >
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <Hidden mdDown>
          <Logo />
        </Hidden>
        <SmallLogoIcon
          width={25}
          height={42}
          classes={{ root: classes.smallLogo }}
        />
      </Grid>
    </Grid>
    <List className={classes.menuItems}>
      {
        menuItems
          .filter(menu => (!menu.roles || menu.roles.includes(userRole)))
          .map(({ label, path, icon }) => (
            <MenuItem
              key={path}
              label={label}
              path={path}
              icon={icon}
            />
          ))
      }
    </List>
  </Drawer>
);

export default Sidebar;

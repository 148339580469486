import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PlusIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 114 114" {...props}>
    <path
      fill="currentColor"
      d="M56.924 33.6832C54.0664 33.7288 51.7864 36.0848 51.832 38.9424V51.832H38.9424C36.0848 51.8016 33.7288 54.0816 33.6832 56.9392C33.6528 59.812 35.9328 62.1528 38.7904 62.1984H38.9272H51.8168V75.088C51.7864 77.9456 54.0664 80.3016 56.924 80.3472C59.7816 80.3776 62.1376 78.0976 62.1832 75.24V75.1032V62.2136H75.0728C77.9304 62.244 80.2864 59.964 80.332 57.1064C80.3624 54.2488 78.0824 51.8928 75.2248 51.8472H75.088H62.1984V38.9728C62.244 36.1152 59.9488 33.7592 57.0912 33.7136L56.924 33.6832ZM57 10.3664C82.8248 10.3664 103.634 31.1752 103.634 57C103.634 82.8248 82.8248 103.634 57 103.634C31.1752 103.634 10.3664 82.8248 10.3664 57C10.3664 31.1752 31.1752 10.3664 57 10.3664ZM57 0C25.5816 0 0 25.5816 0 57C0 88.4184 25.5816 114 57 114C88.4184 114 114 88.4184 114 57C114 25.5816 88.4184 0 57 0Z"
    />
  </SvgIcon>
);

export default React.memo(PlusIcon);

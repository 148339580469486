import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  FEEDBACK_REQUEST,
} from '../types/feedback';

import {
  sendFeedback,
  sendFeedbackFailed,
  sendFeedbackSuccess,
} from '../actions/feedback';
import { createSuccessSnackBar, createErrorSnackBar } from '../actions/snackbars';
import { sendFeedbackMessage } from '../../services/feedback';

function* sendFeedbackSaga(action: ReturnType<typeof sendFeedback>) {
  try {
    const result = yield call(sendFeedbackMessage, action.payload);
    yield put(sendFeedbackSuccess());
    yield put(createSuccessSnackBar(result.data.message));
  } catch (error) {
    yield put(sendFeedbackFailed());
    yield put(createErrorSnackBar('Failed to sent feedback. Try again later.'));
  }
}

export default all([takeLatest(FEEDBACK_REQUEST, sendFeedbackSaga)]);

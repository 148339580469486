/* eslint-disable @typescript-eslint/camelcase */
import React, { ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/core/styles';
import InputPriceWrapper from '../InputPriceWrapper';

import styles from './styles';
import { PropertyDetailsInfo } from '../../redux/types/properties';
import { CatalogEntity } from '../../redux/types/catalog';
import { State } from '../../redux/types/states';

const getInitialPropertyState = ({
  property_id,
  builder,
  disposition_id,
  house_num,
  address_1,
  subdivision,
  city,
  state,
  zipcode,
  latitude,
  longitude,
  price,
  lock_serial_number,
  lock_status,
  contract_date,
}: PropertyDetailsInfo) => ({
  property_id,
  builder,
  disposition_id,
  house_num,
  address_1,
  subdivision,
  city,
  state,
  zipcode,
  latitude,
  longitude,
  price,
  lock_serial_number,
  lock_status,
  contract_date: contract_date || '',
});

type Props = {
  info: PropertyDetailsInfo;
  loadCatalog: () => void;
  loadingCatalog: boolean;
  dispositionCatalog: Array<CatalogEntity>;
  lockStatusCatalog: Array<CatalogEntity>;
  states: Array<State>;
  loadingStates: boolean;
  loadStates: () => void;
} & WithStyles<typeof styles>;

const PropertyInfoCard: React.FC<Props> = props => {
  const {
    classes,
    info,
    loadCatalog,
    dispositionCatalog,
    lockStatusCatalog,
    loadingCatalog,
    loadStates,
    loadingStates,
    states,
  } = props;
  const [propertyState, setPropertyState] = React.useState(getInitialPropertyState(info));

  React.useEffect(() => {
    loadCatalog();
    loadStates();
  }, [loadCatalog, loadStates]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    console.log({ ...info, ...propertyState });
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPropertyState(prevState => ({ ...prevState, [name]: value }));
  };

  const isPropertySold = dispositionCatalog.find(
    catalogEntity => catalogEntity.catalog_id === propertyState.disposition_id
      && catalogEntity.value.toLowerCase().includes('sold'),
  );

  return (
    <form className={classes.content} onSubmit={onSubmit} noValidate>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Property ID
          </Typography>
          <Typography>
            {propertyState.property_id}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Builder
          </Typography>
          <Typography>
            {propertyState.builder}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="disposition_id"
              name="disposition_id"
              label="Disposition"
              value={propertyState.disposition_id}
              onChange={handleChange}
              disabled={loadingCatalog}
            >
              <MenuItem disabled value="">Select Property Disposition</MenuItem>
              {dispositionCatalog.map(({ catalog_id, value }) => (
                <MenuItem
                  key={catalog_id}
                  value={catalog_id}
                >
                  {value}
                </MenuItem>
              ))}
            </TextField>
            {loadingCatalog && <CircularProgress size={24} className={classes.progress} />}
          </div>
        </Grid>

        {isPropertySold && (
        <Grid item xs={12}>
          <TextField
            type="date"
            required
            fullWidth
            margin="normal"
            id="contract_date"
            name="contract_date"
            label="Contract Date"
            onChange={handleChange}
            value={propertyState.contract_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="house_num"
            name="house_num"
            label="House Number"
            value={propertyState.house_num}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="address_1"
            name="address_1"
            label="Address"
            value={propertyState.address_1}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="subdivision"
            name="subdivision"
            label="Subdivision"
            value={propertyState.subdivision}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="city"
            name="city"
            label="City"
            value={propertyState.city}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="State"
              value={propertyState.state}
              onChange={handleChange}
              disabled={loadingStates}
            >
              <MenuItem value="" disabled>Select State</MenuItem>
              {states.map(({ state_id, state_name }) => (
                <MenuItem
                  key={state_id}
                  value={state_id}
                >
                  {state_name}
                </MenuItem>
              ))}
            </TextField>
            {loadingStates && <CircularProgress size={24} className={classes.progress} />}
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="zipcode"
            name="zipcode"
            label="Zip Code"
            value={propertyState.zipcode}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="latitude"
            name="latitude"
            label="Latitude"
            value={propertyState.latitude}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="longitude"
            name="longitude"
            label="Longitude"
            value={propertyState.longitude}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <InputPriceWrapper>
            <TextField
              required
              fullWidth
              margin="normal"
              id="price"
              name="price"
              label="Price"
              value={propertyState.price}
              onChange={handleChange}
            />
          </InputPriceWrapper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="lock_serial_number"
            name="lock_serial_number"
            label="Lock Serial Number"
            value={propertyState.lock_serial_number}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="lock_status"
              name="lock_status"
              label="Lock Status"
              value={propertyState.lock_status}
              onChange={handleChange}
            >
              <MenuItem value="" disabled>Select Lock Status</MenuItem>
              {lockStatusCatalog.map(({ catalog_id, value }) => (
                <MenuItem
                  key={catalog_id}
                  value={catalog_id}
                >
                  {value}
                </MenuItem>
              ))}
            </TextField>
            {loadingCatalog && <CircularProgress size={24} className={classes.progress} />}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.formActions}>
            <Button type="submit" variant="contained">Save</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(PropertyInfoCard);

export type Query = Record<string, string | number>;

// eslint-disable-next-line import/prefer-default-export
export function buildQuery(
  queryObject: Query | undefined,
  prefix = '?',
): string {
  if (!queryObject) {
    return '';
  }

  const query = Object.entries(queryObject)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .join('&');

  return `${prefix}${query}`;
}

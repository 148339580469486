/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import SearchBar from '../../components/SearchBar';
import Table from '../../components/CustomTable';
import { formatDate } from '../../utils/date';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Customer } from '../../redux/types/customers';
import { Order } from '../../utils/table';
import { Query } from '../../utils/query';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE } from '../../constants/table';
import TablePagination from '../../components/CustomTablePagination';

type Props = {
  customers: Array<Customer>;
  customersTotal: number;
  loading: boolean;
  loadCustomers: (query: Query) => void;
  exportCustomers: (selection: Array<number | string>) => void;
  printCustomers: (selection: Array<number | string>) => void;
} & WithStyles<typeof styles> &
RouteComponentProps;

const headRows: Array<HeaderRowConfig<Customer>> = [
  {
    key: 'firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'visitor_type_id_value',
    align: 'center',
    disablePadding: true,
    label: 'Visitor Type',
  },
  {
    key: 'visits_count',
    align: 'center',
    disablePadding: true,
    label: 'Visit Counter',
  },
  {
    key: 'last_visit_date',
    align: 'right',
    disablePadding: false,
    label: 'Last Use',
    prepareData: ({ last_visit_date }) => formatDate(last_visit_date),
  },
  {
    key: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Created',
    prepareData: ({ created_on }) => formatDate(created_on),
  },

  {
    key: 'last_visit_property_id',
    align: 'center',
    disablePadding: false,
    label: 'Last Property',
  },
  {
    key: 'visitor_status_id_value',
    align: 'center',
    disablePadding: false,
    label: 'Last Status',
  },
];

const searchBarPlaceholder: string = 'Search all customers';

const Customers: React.FC<Props> = props => {
  const {
    classes,
    history,
    match,
    customers,
    customersTotal,
    loading,
    loadCustomers,
    exportCustomers,
    printCustomers,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Customer>('last_visit_date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE);
  const [selected, setSelected] = useState<Array<Customer['customer_id']>>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    loadCustomers({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, loadCustomers, search]);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Customer) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  function handleSelect(newSelected: Array<any>) {
    setSelected(newSelected);
  }

  function handleCustomerSelect(customer: Customer) {
    history.push(`${match.url}/${customer.customer_id}`);
  }

  function handleCustomersExport() {
    if (selectedLength !== 0) {
      exportCustomers(selected);
    }
  }

  function handleCustomersPrint() {
    if (selectedLength !== 0) {
      printCustomers(selected);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} onChange={setSearch} />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Customers
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button size="small">Delete</Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleCustomersExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleCustomersPrint}
              >
                Print
              </Button>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Customer>
            hover
            className={classes.table}
            IDKey="customer_id"
            selected={selected}
            data={customers}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleCustomerSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={customers.length}
          rowsTotal={customersTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Customers);

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import AgentForm from './AgentForm';
import styles from './styles';
import { addAgentRequest } from '../../redux/actions/agents';
import { selectAgentsRequestStatus } from '../../redux/selectors/agents';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  loading: selectAgentsRequestStatus(state),
});

const mapDispatchToProps = {
  addAgent: addAgentRequest,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AgentForm));

export const STATES_REQUEST = 'STATES_REQUEST';
export const STATES_REQUEST_SUCCESS = 'STATES_REQUEST_SUCCESS';
export const STATES_REQUEST_FAILED = 'STATES_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type State = {
  state_id: string;
  state_name: string;
};

export type StatesRequestSuccess = Array<State>;

export type StatesState = {
  items: Array<State>;
  loading: boolean;
  error: ErrorMessage['message'];
};

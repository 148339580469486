import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from '@material-ui/core/CircularProgress';

import AutoSuggest from '../../components/AutoSuggest';
import Tabs from '../../components/CustomTabs';
import PropertyInfoCard from '../../components/PropertyInfoCard';
import TimeLine from '../../components/TimeLine';
import TimeLineItem from '../../components/TimeLineItem';
import { PropertyDetails as PropertyDetailsType } from '../../redux/types/properties';
import { formatDate, timeFormat } from '../../utils/date';

import styles from './styles';
import { Agent } from '../../redux/types/agents';

type Props = {
  loadPropertyDetails: (propertyId: number | string) => void;
  propertyDetails: PropertyDetailsType | null;
  loading?: boolean;
  updatingProperties?: boolean;
  agents: Array<Agent>;
  loadAgents: () => void;
  addAgent: (payload: { propertyId: number | string, agentId: string }) => void;
  removeAgent: (payload: { propertyId: number | string, agentId: string }) => void;
  loadingAgents: boolean;
  isPM: boolean;
} & WithStyles<typeof styles> &
RouteComponentProps<{ propertyId: string }>;

const PropertyDetails: React.FC<Props> = props => {
  const {
    classes,
    loadPropertyDetails,
    propertyDetails,
    match,
    agents,
    loadAgents,
    loadingAgents,
    updatingProperties,
    addAgent,
    removeAgent,
    isPM,
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [newAgent, setNewAgent] = useState<string>('');

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewAgent(e.currentTarget.value);
  }

  function handleItemClick(e: React.MouseEvent<HTMLDivElement>, value: string) {
    setNewAgent(value);
  }

  useEffect(() => {
    loadAgents();
    loadPropertyDetails(match.params.propertyId);
  }, [loadPropertyDetails, match.params.propertyId, loadAgents]);

  if (
    propertyDetails
    && propertyDetails.info.property_id !== Number(match.params.propertyId)
  ) {
    return (
      <>
        <div className={classes.actionsBar}>
          <div className={classes.actionButtonsWrapper}>
            <Button
              variant="contained"
              className={classes.backToButton}
              component={Link}
              to="/properties"
            >
              <ArrowLeftIcon />
            Properties
            </Button>
            <Button
              variant="contained"
              className={classes.actionButton}
            >
            Action
            </Button>
          </div>
        </div>
        <div className={classes.progressWrapper}>
          <Spinner className={classes.progress} />
        </div>
      </>
    );
  }

  function onAddAgent() {
    addAgent({ propertyId: match.params.propertyId, agentId: newAgent });
    setNewAgent('');
  }

  function onRemoveAgent(agentId: string) {
    removeAgent({ agentId, propertyId: match.params.propertyId });
  }

  function handleChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  const listItems = agents.map(({ username }) => username);

  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/properties"
          >
            <ArrowLeftIcon />
            Properties
          </Button>
          <Button
            variant="contained"
            className={classes.actionButton}
          >
            Action
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container spacing={4}>
          {propertyDetails && (
            <>
              <Grid item xs={12} md={4}>
                <PropertyInfoCard info={propertyDetails.info} />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className={classes.rightSide}>
                  <Tabs value={tabIndex} onChange={handleChange}>
                    <Tab label="Timeline" />
                    <Tab label="Agents" />
                  </Tabs>
                  <div className={classes.tabsContent}>
                    {tabIndex === 0 && (
                      <TimeLine>
                        {propertyDetails.visits.map(visit => (
                          <TimeLineItem key={visit.customer_visit_id}>
                            <div>
                              <Typography className={classes.timestamp}>
                                {`${formatDate(visit.modified_on)} ${formatDate(
                                  visit.modified_on,
                                  timeFormat,
                                )}`}
                              </Typography>
                              <Typography>
                                {`${visit.firstname} ${visit.lastname}, ${
                                  visit.cell_phone_1
                                }:`}
                              </Typography>
                              <Typography>{visit.remarks}</Typography>
                            </div>
                          </TimeLineItem>
                        ))}
                      </TimeLine>
                    )}
                    {tabIndex === 1 && (
                      <List component="ul">
                        {
                          !loadingAgents && isPM && (
                            <ListItem>
                              <ListItemAvatar><div /></ListItemAvatar>
                              <AutoSuggest
                                placeholder="Type agent username here.."
                                value={newAgent}
                                listItems={listItems}
                                onItemClick={handleItemClick}
                                onChange={handleInputChange}
                              />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" disabled={!newAgent || updatingProperties} onClick={onAddAgent}>
                                  <AddIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        }
                        {propertyDetails.agents
                          .filter(agent => Boolean(agent.agent_username))
                          .map(agent => {
                            const agentRole = agent.is_project_manager === 1 ? 'PM' : 'AG';
                            const listItemTextContent = `${agentRole}: ${agent.firstname} ${agent.lastname}`;
                            const handleClick = () => onRemoveAgent(agent.agent_username);

                            return (
                              <ListItem component="li" key={listItemTextContent}>
                                <ListItemAvatar>
                                  <Avatar component="div">
                                    <PersonIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>{listItemTextContent}</ListItemText>
                                {
                                  isPM && (
                                  <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={handleClick}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                  )
                                }
                              </ListItem>
                            );
                          })}
                      </List>
                    )}
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);

import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';

import { selectAuthData } from '../../redux/selectors/auth';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  isAuthorized: Boolean(selectAuthData(state).token),
  userRole: selectAuthData(state).isPM ? 'PM' : 'USER',
});

export default connect(mapStateToProps)(PrivateRoute);

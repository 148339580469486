import {
  STATES_REQUEST,
  STATES_REQUEST_SUCCESS,
  STATES_REQUEST_FAILED,
  StatesRequestSuccess,
  ErrorMessage,
} from '../types/states';

export const statesRequest = () => ({
  type: STATES_REQUEST,
} as const);

export const statesRequestFailed = (payload: ErrorMessage) => ({
  type: STATES_REQUEST_FAILED,
  payload,
} as const);

export const statesRequestSuccess = (payload: StatesRequestSuccess) => ({
  type: STATES_REQUEST_SUCCESS,
  payload,
} as const);

export type StatesActionsTypes =
  | ReturnType<typeof statesRequest>
  | ReturnType<typeof statesRequestFailed>
  | ReturnType<typeof statesRequestSuccess>;

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { WithStyles } from '@material-ui/styles';
import DeviceWidget from '../../components/DeviceWidget';
import SearchBar from '../../components/SearchBar';
import AddForm from './AddForm';

import styles from './styles';

import { Lock } from '../../redux/types/locks';
import { Query } from '../../utils/query';

type Props = {
  locksActive: Array<Lock>;
  locksDown: Array<Lock>;
  locksOther: Array<Lock>;
  loadLocks: (query?: Query) => void;
} & WithStyles<typeof styles>;

const searchBarPlaceholder = 'Search inventory by device name';

const Inventory: React.FC<Props> = props => {
  const {
    classes,
    locksActive,
    locksDown,
    locksOther,
    loadLocks,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>('Active Locks');
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // function openForm(): void {
  //   setOpen(true);
  // }

  function closeForm(): void {
    setOpen(false);
  }

  useEffect(() => {
    loadLocks();
  }, [loadLocks]);

  function renderLocksList(locks: Array<Lock>, heading: string) {
    if (locks.length === 0) {
      return null;
    }
    return (
      <ExpansionPanel
        classes={{ root: classes.expandRoot }}
        expanded={expanded === heading}
        onChange={handleChange(heading)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${heading}-panel1bh-content`}
          id={`${heading}-panel1bh-header`}
        >
          <Typography color="textSecondary">{heading}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={4}>
            {locks.map(lock => (
              // if lock serial not unique for different versions
              <Grid
                key={`${lock.version}-${lock.serial_number}-${lock.property_id}`}
                item
                xs={6}
                sm={3}
              >
                <DeviceWidget device={lock} />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>


    );
  }

  return open ? (
    <Grid container>
      <AddForm closeForm={closeForm} />
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} />
        </Grid>
      </Grid>

      <div className={classes.widgetsWrapper}>
        {renderLocksList(locksActive, 'Active Locks')}
        {renderLocksList(locksDown, 'Down Locks')}
        {renderLocksList(locksOther, 'Other Locks')}
      </div>
    </>
  );
};

export default React.memo(Inventory);

import React, { useState } from 'react';
import InputMask, { InputState } from 'react-input-mask';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { WithStyles } from '@material-ui/styles';
import validationSchema from './validationSchema';

import styles from './styles';
import { AgentDetails } from '../../redux/types/agents';

type FormData = {
  password: string;
  confirm: string;
} & AgentDetails;

type Props = {
  user: AgentDetails;
  onSave: (payload: Record<string, string | number>) => void;
} & WithStyles<typeof styles>;

const EditProfile: React.FC<Props> = props => {
  const { classes, user, onSave } = props;

  const [formData, setFormData] = useState<FormData>(() => {
    let { phone } = user;

    if (phone && phone.length === 10) {
      phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, (...match) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [fullMatch, g1, g2, g3] = match;

        return `${g1}-${g2}-${g3}`;
      });
    }

    return {
      ...user, phone, password: '', confirm: '',
    };
  });
  const [inputErrors, setInputErrors] = useState({
    firstname: '', lastname: '', phone: '', email: '', password: '',
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;

    setFormData(prevState => ({ ...prevState, [name]: value }));
    setInputErrors(prevState => ({ ...prevState, [name]: '' }));
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (formData.password !== formData.confirm) {
      const password = 'Password doesn\'t match.';

      setInputErrors(prevState => ({ ...prevState, password }));
    } else {
      const {
        error,
        value,
      } = validationSchema.validate(formData, { abortEarly: false, stripUnknown: true });

      if (!error) {
        const { confirm, password, ...userInput } = value;

        onSave({
          ...userInput,
          ...(password && { password }),
        });
      }

      if (error && error.isJoi) {
        const { details } = error;
        const errorsEntries = details.map(({ message, path }) => [path[0], message]);
        setInputErrors(Object.fromEntries(errorsEntries));
      }
    }
  }

  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled
              variant="filled"
              margin="normal"
              id="username"
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="filled"
              margin="normal"
              id="firstName"
              label="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              error={Boolean(inputErrors.firstname)}
              helperText={inputErrors.firstname}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              error={Boolean(inputErrors.lastname)}
              helperText={inputErrors.lastname}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="999-999-9999"
              onChange={handleChange}
              value={formData.phone}
            >
              {(inputProps: InputState) => (
                <TextField
                  {...inputProps}
                  required
                  fullWidth
                  variant="filled"
                  type="tel"
                  margin="normal"
                  id="phone"
                  label="Phone"
                  name="phone"
                  error={Boolean(inputErrors.phone)}
                  helperText={inputErrors.phone}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(inputErrors.email)}
              helperText={inputErrors.email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(inputErrors.password)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="confirm"
              label="Confirm Password"
              name="confirm"
              type="password"
              value={formData.confirm}
              onChange={handleChange}
              error={Boolean(inputErrors.password)}
              helperText={inputErrors.password}
            />
          </Grid>
        </Grid>
        <div className={classes.cardActions}>
          <Grid container justify="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default React.memo(EditProfile);

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Inventory from './Inventory';
import { selectLocksActive, selectLocksDown, selectLocksOther } from '../../redux/selectors/locks';
import { locksRequest } from '../../redux/actions/locks';

import styles from './styles';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  locksActive: selectLocksActive(state),
  locksDown: selectLocksDown(state),
  locksOther: selectLocksOther(state),
});

const mapDispatchToProps = {
  loadLocks: locksRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Inventory),
);

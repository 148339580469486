import { all, fork } from 'redux-saga/effects';
import authSaga from './auth';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import locks from './locks';
import stats from './stats';
import catalog from './catalog';
import states from './states';
import agents from './agents';
import appInit from './appInit';
import split from './split';
import feedback from './feedback';
import billing from './billing';

export default function* rootSaga() {
  yield fork(split);
  yield all([
    authSaga,
    properties,
    customers,
    visits,
    locks,
    stats,
    catalog,
    states,
    agents,
    appInit,
    feedback,
    billing,
  ]);
}

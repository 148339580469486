import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  content: {
    height: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px 30px',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  button: {
    color: theme.appPalette.orange,
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: 8,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  progressWrapper: {
    position: 'relative',
  },
  progress: {
    color: theme.appPalette.orange,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

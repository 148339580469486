import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { WithStyles } from '@material-ui/styles';
import StatusBadge from '../../../components/StatusBadge';
import TypeBadge from '../../../components/TypeBadge';
import { ReactComponent as FullProfileIcon } from '../../../assets/fullProfile.svg';


import styles from './styles';
import { CustomerDetails } from '../../../redux/types/customers';

type Props = {
  loading: boolean;
  customerDetails: CustomerDetails | null;
  onClose: () => void;
} & WithStyles<typeof styles>;

const DrawerContent: React.FC<Props> = props => {
  const {
    classes, onClose, customerDetails, loading,
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);

  function toggleTab(indexTab = 0): () => void {
    return (): void => setTabIndex(indexTab);
  }

  if (!customerDetails || loading) {
    return null;
  }

  function renderActivity() {
    return (
      <Grid container className={classes.tabContainer}>
        <Grid item xs={6}>
          <Typography color="textSecondary">Status</Typography>
          <StatusBadge status="Accessed" />
        </Grid>
        <Grid item xs={6}>
          <Typography color="textSecondary">Type</Typography>
          <TypeBadge type="AG" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.tabTitle}>
            Contact Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary">Email</Typography>
          <Typography className={classes.email}>b.morris@mail.com</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary">Phone</Typography>
          <Typography>555-555-5555</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary">Address</Typography>
          <Typography>123 ABC St. Anytown, TX 55555</Typography>
        </Grid>
      </Grid>
    );
  }

  function renderDetails() {
    return null;
  }

  function getTabContent(indexTab: number) {
    switch (indexTab) {
      case 0:
        return renderActivity();
      case 1:
        return renderDetails();
      default:
        return null;
    }
  }

  return (
    <div className={classes.drawerContent}>
      <Grid container justify="flex-end">
        <Grid item xs={1}>
          <IconButton className={classes.iconClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container className={classes.row} justify="center">
        <Avatar className={classes.avatar}>BM</Avatar>
      </Grid>

      <Grid container className={classes.row}>
        <Grid item xs>
          <Typography className={classes.userInfo}>Brenda Morris</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.row} justify="center">
        <Link to="/customers/:customerId" className={classes.userProfileLink}>
          <FullProfileIcon className={classes.icon} />
          <span>View Full Profile</span>
        </Link>
      </Grid>

      <Grid container className={classes.row} justify="center">
        <Tabs classes={{ root: classes.tabsRoot }} value={tabIndex}>
          <Tab label="Activity" onClick={toggleTab(0)} />
          <Tab label="Details" onClick={toggleTab(1)} />
        </Tabs>
      </Grid>

      {getTabContent(tabIndex)}
    </div>
  );
};

export default React.memo(DrawerContent);

import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    '& > div': {
      paddingLeft: 43,
      paddingRight: 43,
      '&:last-child': {
        paddingBottom: theme.spacing(4),
      },
    },
  },
  header: {
    paddingTop: theme.spacing(8),
    backgroundColor: '#E9ECF2',
  },
  titleWrapper: {
    margin: '8px 0',
  },
  title: {
    fontSize: 28,
  },
  content: {},
  button: {
    padding: '10px 40px',
    textTransform: 'capitalize',
    fontSize: 16,
    boxShadow: theme.shadows[3],
  },
  cardActions: {
    marginTop: 45,
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.appPalette.orange,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

import Joi from '@hapi/joi';

export const username = Joi.string()
  .label('User name')
  .min(1)
  .max(250)
  .required();

export const firstname = Joi.string()
  .label('First name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

export const lastname = Joi.string()
  .label('Last name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

export const email = Joi.string()
  .label('Email')
  .email({ minDomainSegments: 2, tlds: false })
  .required();

export const phone = Joi.string()
  .label('Phone')
  .regex(/^\d{3}-\d{3}-\d{4}$/, 'required')
  .required();

export const agentValidationSchema = Joi.object({
  username,
  firstname,
  lastname,
  email,
  phone,
});

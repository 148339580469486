import { createStyles } from '@material-ui/core';

export default () => createStyles({
  widgetsWrapper: {
    marginTop: 33,
    width: '100%',
  },
  expandRoot: {
    backgroundColor: '#fefefe',
  },
});

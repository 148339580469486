import {
  SPLIT_FEATURES_REQUEST_SUCCESS,
  SPLIT_FEATURES_REQUEST_FAILED,
} from '../types/split';

export const featuresRequestSuccess = (payload: Record<string, 'on' | 'off'>) => ({
  type: SPLIT_FEATURES_REQUEST_SUCCESS,
  payload,
} as const);

export const featuresRequestFailed = (payload: string) => ({
  type: SPLIT_FEATURES_REQUEST_FAILED,
  payload,
} as const);

export type SplitActions = ReturnType<typeof featuresRequestSuccess>
| ReturnType<typeof featuresRequestFailed>;

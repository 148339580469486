import axios from './axios';
import { Query, buildQuery } from '../utils/query';

export const loadVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/visits${query}`);
};

export const loadVisitsExport = (
  selection: Array<number | string>,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    '/visits/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

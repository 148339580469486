import { createSelector } from 'reselect';
import { AppState } from '../store';
import { AgentsState } from '../types/agents';

const selectAgentsState = (state: AppState) => state.agents;
const selectItems = (state: AgentsState) => state.items;
const selectTotal = (state: AgentsState) => state.total;
const selectLoading = (state: AgentsState) => state.loading;
const selectAgent = (state: AgentsState) => state.selected;

export const selectAgents = createSelector(
  selectAgentsState,
  selectItems,
);

export const selectAgentSelected = createSelector(
  selectAgentsState,
  selectAgent,
);

export const selectTotalAgents = createSelector(
  selectAgentsState,
  selectTotal,
);

export const selectAgentsRequestStatus = createSelector(
  selectAgentsState,
  selectLoading,
);

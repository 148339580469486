import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  autoSuggestWrapper: {
    position: 'relative',
  },
  listWrapper: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: '5px',
  },
});

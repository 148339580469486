import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  customersRequest,
  customersExportRequest,
  customersExportAndPrintRequest,
} from '../../redux/actions/customers';
import {
  selectCustomers,
  selectTotalCustomers,
  selectCustomersRequestStatus,
} from '../../redux/selectors/customers';
import Customers from './Customers';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  customers: selectCustomers(state),
  customersTotal: selectTotalCustomers(state),
  loading: selectCustomersRequestStatus(state),
});

const mapDispatchToProps = {
  loadCustomers: customersRequest,
  exportCustomers: customersExportRequest,
  printCustomers: customersExportAndPrintRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Customers),
);

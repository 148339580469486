import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    height: '100%',
    // '&:hover': {
    //   boxShadow: '0 1px 4px 6px rgba(0,0,0,0.14)',
    //   cursor: 'pointer',
    //   transition: 'box-shadow .3s ease-in',
    // },
  },
  iconWrapper: {
    textAlign: 'center',
    margin: '31px 0',
    lineHeight: 0,
  },
  icon: {
    fontSize: 114,
    color: '#DB5C0E',
  },
  primaryText: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
    '& .deviceId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
  },
});

import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  content: {
    height: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px 30px',
  },
  row: {
    marginTop: 21,
  },
  rootAvatar: {
    margin: '0 auto',
    width: 81,
    height: 81,
    fontSize: 32,
    textTransform: 'uppercase',
    backgroundColor: theme.appPalette.orange,
  },
  customerName: {
    fontSize: 32,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
  },
  email: {
    color: theme.appPalette.orange,
  },
  badgeBase: {
    display: 'inline-block',
    padding: '10px 25px',
    borderRadius: 5,
    fontSize: 18,
    color: '#fff',
  },
  customerStatus: {
    backgroundColor: '#04A777',
  },
  customerType: {
    backgroundColor: '#040620',
    textTransform: 'uppercase',
  },
  statusAndType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

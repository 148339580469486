/* eslint-disable react/no-array-index-key */
import React from 'react';
import { WithStyles } from '@material-ui/styles';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';
import classNames from 'classnames';
import { Invoice } from '../../../redux/types/billing';

import styles from './styles';

type Props = {
  invoiceDetails: Invoice,
  headers: Array<string>,
  tableBody: string [][] | null,
  hideBorder?: boolean,
  innerBorder?: boolean,
  billTable?: boolean,
  bodyClass?: boolean,
  alignSelfLeft?: boolean,
  periodWidth?: boolean
} & WithStyles<typeof styles>;

const InvoiceTable: React.FC<Props> = (props) => {
  const {
    classes,
    headers,
    tableBody,
    hideBorder,
    innerBorder,
    billTable,
    bodyClass,
    alignSelfLeft,
    periodWidth,
  } = props;

  const cardClasses = classNames({
    [classes.root]: true,
    [classes.border]: innerBorder,
    [classes.bill]: billTable,
    [classes.alignLeft]: alignSelfLeft,
    [classes.periodWidth]: periodWidth,
  });

  const cellClasses = classNames({
    [classes.hideBorder]: hideBorder,
  });

  const bodyClasses = classNames({
    [classes.bodyClass]: bodyClass,
  });

  return (
    <Table
      className={cardClasses}
    >
      <TableHead>
        <TableRow>
          {headers.map((header: string) => (<TableCell key={header} align={billTable ? 'left' : 'center'}>{header}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody className={bodyClasses}>
        {
        tableBody && tableBody.map((row, index) => (
          <TableRow key={index}>
            {
                row.map((cell, ind) => (
                  <TableCell key={ind} className={cellClasses} align={billTable ? 'left' : 'center'}>
                    {cell}
                  </TableCell>
                ))
            }
          </TableRow>
        ))
        }
      </TableBody>
    </Table>
  );
};

export default InvoiceTable;

import React, { useState, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { WithStyles } from '@material-ui/core/styles';
import { transformUsername } from '../../utils/user';
import styles from './styles';

type Props = {
  listItems?: Array<string>
  onItemClick?: (event: React.MouseEvent<HTMLDivElement>, value: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder?: string;
} & WithStyles<typeof styles>;

const AutoSuggest: React.FC<Props> = props => {
  const {
    value,
    placeholder,
    classes,
    onChange,
    onItemClick,
    listItems = [],
  } = props;
  const [position, setPosition] = useState<Record<string, string | number>>({ top: 'unset', left: 'unset' });
  const [open, setOpenList] = useState<boolean>(false);

  const listWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const wrapperRef = useCallback((wrapper: HTMLDivElement) => {
    if (wrapper) {
      const { height } = wrapper.getBoundingClientRect();

      setPosition({ top: height + 8, left: 0 });
    }
  }, [setPosition]);

  function closeList() {
    setOpenList(false);
  }

  function openList() {
    setOpenList(true);
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length >= 1) {
      openList();
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (typeof onChange === 'function') {
      onChange(e);
    }

    if (e.currentTarget.value.length >= 1) {
      openList();
    } else {
      closeList();
    }
  }

  function handleItemClick(itemValue: string) {
    return (e: React.MouseEvent<HTMLDivElement>) => {
      if (typeof onItemClick === 'function') {
        onItemClick(e, itemValue);
      }
      closeList();
    };
  }

  React.useEffect(() => {
    function handler(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (!listWrapperRef.current || !listWrapperRef.current.contains(target)) {
        closeList();
      }
    }

    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  }, []);

  const filter = (item: typeof value) => !value || item.toLowerCase().includes(value.toLowerCase());
  const filteredList = listItems.filter(filter).map(transformUsername);

  return (
    <div ref={wrapperRef} className={classes.autoSuggestWrapper}>
      <TextField
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      {open && filteredList.length > 0 && (
      <div ref={listWrapperRef} style={position} className={classes.listWrapper}>
        <List>
          {
            filteredList.map(item => (
              <ListItem
                button
                key={item}
                onClick={handleItemClick(item)}
              >
                <ListItemText>
                  {item}
                </ListItemText>
              </ListItem>
            ))
          }
        </List>
      </div>
      )}
    </div>
  );
};

export default AutoSuggest;

import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  rootListItem: {
    borderRadius: 10,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    marginBottom: 16,
    '&:first-of-type': {
      marginBottom: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 1px 6px 6px rgba(0,0,0,0.14)',
    },
    transition: 'box-shadow 0.4s ease',
  },
  listContent: {
    padding: '12px 30px 12px 20px',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  avatarWrapper: {
    flex: '0 0 auto',
    marginRight: 16,
    textTransform: 'capitalize',
  },
  avatar: {
    width: 68,
    height: 68,
    color: '#fff',
    fontSize: 24,
    backgroundColor: theme.appPalette.orange,
  },
  userWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  spacer: {
    flexGrow: 1,
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  userInfo: {
    width: '100%',
    display: 'flex',
  },
  text: {
    fontSize: 18,
    margin: 0,
    padding: 0,
  },
  name: {
    fontSize: 20,
    margin: 0,
    padding: 0,
    color: theme.appPalette.orange,
  },
  userContacts: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  activitiesWrapper: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 300,
  },
  activity: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '50%',
  },
  moreButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rootIconButton: {
    padding: 3,
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      backgroundColor: 'none',
      color: theme.appPalette.orange,
    },
  },
});

import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { Order, isSelected, countEmptyRows } from '../../utils/table';

export type HeaderRowConfig<T extends object> = {
  key: keyof T,
  align: TableCellProps['align'],
  disablePadding: boolean,
  label: string,
  prepareData?: (data: T) => string | number,
  render?: (data: T) => React.ReactNode,
};

export type CustomTableProps<T extends object> = {
  hover?: boolean,
  className?: string,
  header: Array<HeaderRowConfig<T>>,
  data: Array<T>,
  order: Order,
  orderBy: keyof T,
  IDKey: keyof T,
  rowsPerPage: number,
  // you need provide none or both 'selected' & 'onSelect'
  selected?: Array<any>,
  onSelect?: (selected: Array<any>) => void,
  onChangeOrder: (newOrder: Order, newOrderBy: keyof T) => void,
  onRowClick?: (rowData: T) => void,
};

const InputProps = { 'aria-label': 'Select all Properties' };

const CustomTable = <T extends object>(props: CustomTableProps<T>) => {
  const {
    className,
    header,
    data,
    order,
    orderBy,
    onSelect,
    onChangeOrder,
    onRowClick,
    selected = [],
    IDKey,
    rowsPerPage,
    hover,
  } = props;

  const rowCount = data.length;
  const numSelected = selected.length;
  const Indeterminate = numSelected > 0 && numSelected < rowCount;
  const selectedAll = numSelected === rowCount;
  const emptyRows = countEmptyRows(rowCount, rowsPerPage);
  const emptyRowsStyle = React.useMemo(() => ({ height: 49 * emptyRows }), [emptyRows]);
  const colSpan = onSelect ? header.length + 1 : header.length;

  function handleRequestSort(
    key: keyof T,
  ) {
    return (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
      event.preventDefault();

      const isDesc = orderBy === key && order === 'desc';

      onChangeOrder(isDesc ? 'asc' : 'desc', key);
    };
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (!onSelect) {
      return;
    }

    if (event.target.checked) {
      const newSelected = data.map(d => d[IDKey]);
      onSelect(newSelected);
      return;
    }
    onSelect([]);
  }

  function handleRowClick(rowData: T) {
    return (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (onRowClick) {
        onRowClick(rowData);
      }
    };
  }

  function handleSelectClick(id: any) {
    return (event: React.SyntheticEvent) => {
      if (!onSelect) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const selectedIndex = selected.indexOf(id);
      let newSelected: Array<any> = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      onSelect(newSelected);
    };
  }

  function renderTableRow(rowData: T, i: number) {
    const labelId: string = `enhanced-table-checkbox-${i}`;

    return (
      <TableRow
        hover={hover}
        key={String(rowData[IDKey])}
        onClick={handleRowClick(rowData)}
      >
        {
          onSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isSelected(rowData[IDKey], selected)}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={handleSelectClick(rowData[IDKey])}
            />
          </TableCell>
          )
        }
        {
          header.map(({
            key, prepareData, render, align,
          }) => {
            if (render) {
              return (<Fragment key={String(key)}>{render(rowData)}</Fragment>);
            }
            return (
              <TableCell
                align={align}
                key={String(key)}
              >
                {prepareData ? prepareData(rowData) : rowData[key]}
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  }

  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          {
            onSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={Indeterminate}
                checked={selectedAll}
                onChange={handleSelectAllClick}
                inputProps={InputProps}
              />
            </TableCell>
            )
          }
          {header.map(h => (
            <TableCell
              key={String(h.key)}
              align={h.align}
              padding={h.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === h.key ? order : false}
              onClick={handleRequestSort(h.key)}
            >
              {h.label}
              <TableSortLabel
                active={orderBy === h.key}
                direction={order}
                IconComponent={ArrowIcon}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(renderTableRow)}
        {emptyRows > 0 && (
          <TableRow style={emptyRowsStyle}>
            <TableCell colSpan={colSpan} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default CustomTable;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import { WithStyles } from '@material-ui/core/styles';
import Tabs from '../CustomTabs';
import ActivityFeedItem from '../ActivityFeedItem';

import styles from './styles';
import { CustomerVisits } from '../../redux/types/customers';

type Props = {
  customerActivity: Array<CustomerVisits>;
} & WithStyles<typeof styles>;

const CustomerInfoWorkbench: React.FC<Props> = props => {
  const { classes, customerActivity } = props;
  // const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Tabs value={0}>
          <Tab label="Activity" />
        </Tabs>
      </div>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>Activity</Typography>
      </div>
      <div className={classes.content}>
        {customerActivity.map(activity => (
          <ActivityFeedItem key={activity.customer_visit_id} activity={activity} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(CustomerInfoWorkbench);

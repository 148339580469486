import React from 'react';
import { Line } from 'react-chartjs-2';
import CustomCard from '../CustomCard';
import CustomCardHeader from '../CustomCardHeader';

type Props = {
  title?: string;
  subtitle?: string;
  options?: Record<string, any>;
  chartDataSet?: Record<string, any>;
  chartData: Array<string | number>;
  chartLabels: Array<string | number>;
};

const chartOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
  },
  scales: {
    ticks: {
      display: false,
    },
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 14,
          fontSize: 16,
          fontColor: '#040620',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          padding: 14,
          fontSize: 16,
          fontColor: '#B6B6B6',
          beginAtZero: true,
        },
      },
    ],
  },
};

const customDataSet = {
  fill: false,
  lineTension: 0.25,
  borderColor: '#DB5C0E',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'round',
  pointBackgroundColor: '#DB5C0E',
  pointBorderWidth: 0,
  borderWidth: 3,
  pointHoverRadius: 7,
  pointHoverBorderWidth: 0,
  pointRadius: 3,
  pointHitRadius: 10,
};

export interface LineChartData {
  labels: Array<string | number>;
  datasets: Array<Record<string, any>>;
}

const LineChart: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    chartDataSet = {},
    options = chartOptions,
    chartData = [],
    chartLabels = [],
  } = props;

  const data = React.useMemo<LineChartData>(() => {
    const dataSet = { ...customDataSet, ...chartDataSet, data: chartData };

    return {
      labels: chartLabels,
      datasets: [dataSet],
    };
  }, [chartData, chartLabels, chartDataSet]);

  if (chartData.length === 0 || chartLabels.length === 0) {
    return (
      <CustomCard>
        <CustomCardHeader title={title} subtitle={subtitle} color="orange" />
        No data to show
      </CustomCard>
    );
  }

  return (
    <CustomCard>
      <CustomCardHeader title={title} subtitle={subtitle} />
      <Line data={data} options={options} />
    </CustomCard>
  );
};

export default React.memo(LineChart);

import {
  PROPERTIES_REQUEST,
  PROPERTIES_REQUEST_SUCCESS,
  PROPERTIES_REQUEST_FAILED,
  PROPERTIES_EXPORT_REQUEST,
  PROPERTIES_EXPORT_REQUEST_SUCCESS,
  PROPERTIES_EXPORT_REQUEST_FAILED,
  PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_REQUEST_FAILED,
  PROPERTY_DETAILS_REQUEST_SUCCESS,
  PROPERTY_ADD_AGENT_REQUEST,
  PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  PROPERTY_ADD_AGENT_REQUEST_FAILED,
  PROPERTY_REMOVE_AGENT_REQUEST,
  PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
  PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  PropertiesRequestSuccess,
  ErrorMessage,
  PropertyDetails, PropertyDetailsAgent,
} from '../types/properties';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';

export const propertiesRequest = (query?: Query) => ({
  type: PROPERTIES_REQUEST,
  payload: query,
} as const);

export const propertiesRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_REQUEST_FAILED,
  payload,
} as const);

export const propertiesRequestSuccess = (payload: PropertiesRequestSuccess) => ({
  type: PROPERTIES_REQUEST_SUCCESS,
  payload,
} as const);

export const propertiesExportRequest = (selection: Array<number | string>, mimeType: MimeType = 'text/csv') => ({
  type: PROPERTIES_EXPORT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const propertiesExportRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTIES_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const propertiesExportAndPrintRequest = (
  selection: Array<number | string>,
  mimeType: MimeType = 'application/pdf',
) => ({
  type: PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const propertiesExportSuccess = () => ({
  type: PROPERTIES_EXPORT_REQUEST_SUCCESS,
} as const);

export const propertyDetailsRequest = (payload: number | string) => ({
  type: PROPERTY_DETAILS_REQUEST,
  payload,
} as const);

export const propertyDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const propertyDetailsRequestSuccess = (payload: PropertyDetails) => ({
  type: PROPERTY_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const propertyAddAgentRequest = (
  payload: { propertyId: number | string, agentId: string },
) => ({
  type: PROPERTY_ADD_AGENT_REQUEST,
  payload,
} as const);

export const propertyAddAgentFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_ADD_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const propertyAddAgentSuccess = (agent: PropertyDetailsAgent) => ({
  type: PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  payload: agent,
} as const);

export const propertyRemoveAgentRequest = (
  payload: { propertyId: number | string, agentId: string },
) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST,
  payload,
} as const);

export const propertyRemoveAgentFailed = (payload: ErrorMessage) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const propertyRemoveAgentSuccess = (agentID: string) => ({
  type: PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
  payload: agentID,
} as const);

export type PropertiesExportRequestActionType =
  ReturnType<typeof propertiesExportRequest>
  | ReturnType<typeof propertiesExportAndPrintRequest>;

export type PropertiesRequestActionType = ReturnType<typeof propertiesRequest>;
export type PropertyDetailsRequestActionType = ReturnType<typeof propertyDetailsRequest>;
export type PropertiesActionsTypes =
  PropertiesRequestActionType
  | PropertyDetailsRequestActionType
  | ReturnType<typeof propertiesRequestFailed>
  | ReturnType<typeof propertiesRequestSuccess>
  | ReturnType<typeof propertyDetailsRequestFailed>
  | ReturnType<typeof propertyDetailsRequestSuccess>
  | ReturnType<typeof propertyAddAgentRequest>
  | ReturnType<typeof propertyAddAgentSuccess>
  | ReturnType<typeof propertyAddAgentFailed>
  | ReturnType<typeof propertyRemoveAgentRequest>
  | ReturnType<typeof propertyRemoveAgentSuccess>
  | ReturnType<typeof propertyRemoveAgentFailed>;

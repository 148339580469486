import React from 'react';
import { WithStyles } from '@material-ui/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './styles';

type Props = {
  icon: React.ElementType;
  path: string;
  label: string;
} & WithStyles<typeof styles> & RouteComponentProps ;


const MenuItem: React.FC<Props> = ({
  classes, icon: Icon, path, label, history, location,
}) => {
  const isSelected = location.pathname.includes(path);
  return (
    <ListItem className={classes.menuItem} button onClick={() => history.push(path)}>
      <ListItemIcon><Icon className={isSelected ? classes.selectedSvgLink : 'none'} /></ListItemIcon>
      <ListItemText classes={{ primary: classNames(classes.menuItemText, isSelected ? classes.selectedLink : '') }} primary={label} />
    </ListItem>
  );
};
export default withRouter<Props, any>(MenuItem);

import {
  PROPERTIES_REQUEST,
  PROPERTIES_REQUEST_FAILED,
  PROPERTIES_REQUEST_SUCCESS,
  PropertiesState,
  PROPERTY_ADD_AGENT_REQUEST,
  PROPERTY_ADD_AGENT_REQUEST_FAILED,
  PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_REQUEST_FAILED,
  PROPERTY_DETAILS_REQUEST_SUCCESS,
  PROPERTY_REMOVE_AGENT_REQUEST,
  PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
} from '../types/properties';
import { PropertiesActionsTypes } from '../actions/properties';

const initialState: PropertiesState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  updating: false,
  selected: null,
};

export default function PropertiesReducer(
  state = initialState,
  action: PropertiesActionsTypes,
): PropertiesState {
  switch (action.type) {
    case PROPERTY_DETAILS_REQUEST:
    case PROPERTIES_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case PROPERTIES_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case PROPERTY_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    case PROPERTY_DETAILS_REQUEST_FAILED:
    case PROPERTIES_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST:
    case PROPERTY_REMOVE_AGENT_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST_FAILED:
    case PROPERTY_REMOVE_AGENT_REQUEST_FAILED: {
      return {
        ...state,
        updating: false,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST_SUCCESS: {
      const { selected } = state;

      if (selected) {
        const { agents } = selected;

        return {
          ...state,
          selected: {
            ...selected,
            agents: [...agents, action.payload],
          },
          updating: false,
        };
      }

      return {
        ...state,
        updating: false,
      };
    }

    case PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS: {
      const { selected } = state;

      if (selected) {
        const { agents } = selected;

        return {
          ...state,
          selected: {
            ...selected,
            agents: agents.filter(agent => agent.agent_username !== action.payload),
          },
          updating: false,
        };
      }

      return {
        ...state,
        updating: false,
      };
    }

    default: {
      return state;
    }
  }
}

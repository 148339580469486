import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import SearchBar from '../../components/SearchBar';
import List from './List';
import ListItem from './ListItem';
import DrawerContent from './DrawerContent';
import { navTo } from '../../browserHsitory';

import styles from './styles';
import { Query } from '../../utils/query';
import { Customer, CustomerDetails } from '../../redux/types/customers';

type Props = {
  customers: Array<Customer>;
  customer: CustomerDetails | null;
  customersLoading: boolean;
  loadCustomers: (query: Query) => void;
  loadCustomerDetails: (customerId: number) => void;
} & WithStyles<typeof styles>;

const searchBarPlaceholder: string = 'Search all customers';

const WorkBench: React.FC<Props> = props => {
  const {
    classes,
    loadCustomers,
    customers,
    customer,
    customersLoading,
    loadCustomerDetails,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const query = { order: 'desc', orderBy: 'modified_on', limit: 10 };

    loadCustomers(query);
  }, [loadCustomers]);

  function openDrawer(): void {
    setOpen(true);
  }

  function closeDrawer(): void {
    setOpen(false);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} />
        </Grid>
      </Grid>
      <div className={classes.listWrapper}>
        <List>
          {customers.map(customerItem => (
            <ListItem
              key={customerItem.customer_id}
              customerId={customerItem.customer_id}
              firstName={customerItem.firstname}
              lastName={customerItem.lastname}
              type={customerItem.visitor_type_id_value}
              status={customerItem.visitor_status_id_value}
              email={customerItem.email_1}
              cell={customerItem.cell_phone_1}
              onItemClick={customerId => navTo(`/customers/${customerId}`)}
              onMoreButtonClick={(customerId): void => {
                loadCustomerDetails(Number(customerId));
                openDrawer();
              }}
            />
          ))}
        </List>
      </div>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerRoot }}
        open={open}
        onClose={closeDrawer}
      >
        <DrawerContent
          loading={customersLoading}
          customerDetails={customer}
          onClose={closeDrawer}
        />
      </Drawer>
    </>
  );
};

export default React.memo(WorkBench);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Spinner from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from '../../components/SearchBar';
import Table from '../../components/CustomTable';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Agent } from '../../redux/types/agents';
import { Order } from '../../utils/table';
import { Query } from '../../utils/query';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE } from '../../constants/table';
import TablePagination from '../../components/CustomTablePagination';

type Props = {
  agents: Array<Agent>;
  agentsTotal: number;
  loading: boolean;
  loadAgents: (query: Query) => void;
} & WithStyles<typeof styles>;

const searchBarPlaceholder: string = 'Search all agents';

const headRows: Array<HeaderRowConfig<Agent>> = [
  {
    key: 'username',
    align: 'right',
    disablePadding: false,
    label: 'User name',
  },
  {
    key: 'firstname',
    align: 'right',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'right',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'email',
    align: 'right',
    disablePadding: false,
    label: 'Email',
  },
  {
    key: 'phone',
    align: 'right',
    disablePadding: false,
    label: 'Cell',
  },
];

const Agents: React.FC<Props> = props => {
  const {
    classes,
    agents,
    agentsTotal,
    loading,
    loadAgents,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Agent>('username');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE);

  useEffect(() => {
    loadAgents({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, loadAgents, search]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Agent) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} onChange={setSearch} />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Agents
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div>
            <Button variant="outlined" component={Link} to="/agents/add">
              <AddIcon />
              Add agent
            </Button>
          </div>
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Agent>
            className={classes.table}
            IDKey="username"
            data={agents}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onChangeOrder={handleChangeOrder}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={agents.length}
          rowsTotal={agentsTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Agents);

import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  tabsContent: {
    height: '100%',
  },
  timestamp: {
    color: theme.appPalette.orange,
  },
  progressWrapper: {
    position: 'relative',
    height: '100%',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  pageIndexes: {
    flexGrow: 1,
    textAlign: 'center',
    '& span': {
      marginLeft: theme.spacing(1),
      fontSize: 18,
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:hover, &.selected': {
        color: theme.appPalette.orange,
        cursor: 'pointer',
      },
      '&.ellipse:hover': {
        color: 'inherit',
        cursor: 'inherit',
      },
    },
  },
  paginationActions: {
    marginLeft: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
      fontSize: 18,
      textTransform: 'capitalize',
      '&:hover $actionIcon': {
        color: theme.appPalette.orange,
      },
    },
  },
  actionIcon: {
    color: 'inherit',
  },
});

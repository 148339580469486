export const AGENT_DETAILS_REQUEST = 'AGENT_DETAILS_REQUEST';
export const AGENT_DETAILS_REQUEST_SUCCESS = 'AGENT_DETAILS_REQUEST_SUCCESS';
export const AGENT_DETAILS_REQUEST_FAILED = 'AGENT_DETAILS_REQUEST_FAILED';
export const AGENTS_REQUEST = 'AGENTS_REQUEST';
export const AGENTS_REQUEST_SUCCESS = 'AGENTS_REQUEST_SUCCESS';
export const AGENTS_REQUEST_FAILED = 'AGENTS_REQUEST_FAILED';
export const ADD_AGENT_REQUEST = 'ADD_AGENT_REQUEST';
export const ADD_AGENT_REQUEST_SUCCESS = 'ADD_AGENT_REQUEST_SUCCESS';
export const ADD_AGENT_REQUEST_FAILED = 'ADD_AGENT_REQUEST_FAILED';
export const UPDATE_AGENT_REQUEST = 'UPDATE_AGENT_REQUEST';
export const UPDATE_AGENT_REQUEST_SUCCESS = 'UPDATE_AGENT_REQUEST_SUCCESS';
export const UPDATE_AGENT_REQUEST_FAILED = 'UPDATE_AGENT_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type Agent = {
  username: string,
  firstname: string,
  lastname: string,
  phone: string,
  email: string
};

export type AgentDetails = {
  send_sms: number;
  send_email: number;
} & Agent;

export type AgentsRequestSuccess = {
  items: Array<Agent>;
  total: number;
};

export type AgentDetailsRequestSuccess = AgentDetails;

export type AgentsState = {
  items: Array<Agent>;
  total: number;
  loading: boolean;
  error: ErrorMessage['message'];
  selected: AgentDetails | null;
};

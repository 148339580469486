import {
  VISITS_REQUEST,
  VISITS_REQUEST_SUCCESS,
  VISITS_REQUEST_FAILED,
  VisitsState,
} from '../types/visits';
import { VisitsActionsTypes } from '../actions/visits';

const initialState: VisitsState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
};

export default function VisitsReducer(
  state = initialState,
  action: VisitsActionsTypes,
): VisitsState {
  switch (action.type) {
    case VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

import { createSelector } from 'reselect';
import { AppState } from '../store';
import { CatalogState, CatalogEntity } from '../types/catalog';

const selectCatalogState = (state: AppState) => state.appResources.catalog;
const selectItems = (state: CatalogState) => state.items;
const selectLoading = (state: CatalogState) => state.loading;

// 1 Property Disposition
// 2 Visit Condition
// 3 Verification Source
// 4 Verification Code
// 5 Customer Status
// 6 Timezone
// 7 Lock Status
// 8 Ticket Status
// 9 Source Verification
// 10 Closed Diagnosis
// 11 Visitor Type
// 12 Visitor Status

const filterCatalogByID = <T>(
  value: any,
  key: keyof T,
) => (array: Array<T>): Array<T> => array.filter(item => item[key] === value);

export const selectCatalog = createSelector(
  selectCatalogState,
  selectItems,
);

export const selectCatalogRequestStatus = createSelector(
  selectCatalogState,
  selectLoading,
);

export const propertyDispositionCatalog = createSelector(
  selectCatalog,
  filterCatalogByID<CatalogEntity>(1, 'catalog_type_id'),
);

export const lockStatusCatalog = createSelector(
  selectCatalog,
  filterCatalogByID<CatalogEntity>(7, 'catalog_type_id'),
);

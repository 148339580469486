import { createSelector } from 'reselect';
import { AppState } from '../store';
import { AuthState } from '../types/auth';

export type UserData = Pick<
AuthState,
'builderId' | 'firstName' | 'lastName' | 'username' | 'isPM'
>;

export const selectAuthData = (state: AppState) => state.auth;
export const selectLoading = (state: AuthState) => state.loading;
export const selectPmStatus = (state: AuthState) => state.isPM;

export const selectUserData = createSelector(
  selectAuthData,
  ({
    builderId, firstName, lastName, username, isPM,
  }): UserData => ({
    builderId,
    firstName,
    lastName,
    username,
    isPM,
  }),
);

export const selectOauthClients = createSelector(selectAuthData, auth => auth.oauthClients);

export const selectAuthRequestStatus = createSelector(selectAuthData, selectLoading);

export const selectIsPM = createSelector(selectAuthData, selectPmStatus);

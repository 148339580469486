import axios from './axios';
import { Query, buildQuery } from '../utils/query';

export const loadAgents = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/agents${query}`);
};

export const createAgent = (agentPayload: Record<string, any>) => axios.post('/agents', agentPayload);

export const loadAgentById = (id: number | string) => axios.get(`/agents/${id}`);

export const updateAgentById = (
  agentPayload: Record<string, any>,
  id: string | number,
) => axios.patch(`/agents/${id}`, agentPayload);

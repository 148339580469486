import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import FullScreenSpinner from './components/FullScreenSpinner';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './containers/MainLayout';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import SnackBars from './containers/SnackBars';
import routes from './routes';
import { appInit } from './redux/actions/appResources';
import { selectAppInitState } from './redux/selectors/appInit';

import { AppState } from './redux/store';

type Props = {
  initialized: boolean,
  onAppInit: () => void,
};

const App: React.FC<Props> = props => {
  const {
    onAppInit,
    initialized,
  } = props;

  React.useEffect(() => {
    if (!initialized) {
      onAppInit();
    }
  }, [onAppInit, initialized]);

  if (!initialized) {
    return <FullScreenSpinner />;
  }

  return (
    <>
      <CssBaseline />
      <SnackBars />
      <Switch>
        {routes.map(({ path, component: Component, roles }) => (
          <PrivateRoute
            key={path}
            path={path}
            component={Component}
            layout={MainLayout}
            roles={roles}
          />
        ))}
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/login" component={Login} />
        <Redirect exact from="/" to="/dashboard" />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  initialized: selectAppInitState(state).initialized,
});

const mapDispatchToProps = {
  onAppInit: appInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useState } from 'react';
import InputMask, { InputState } from 'react-input-mask';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { agentValidationSchema } from '../../validation/agentValidationSchema';

import styles from './styles';

type Props = {
  addAgent: (agentPayload: Record<string, any>)=> void;
  loading: boolean;
} & WithStyles<typeof styles>;

const AgentForm: React.FC<Props> = props => {
  const {
    classes,
    addAgent,
    loading,
  } = props;

  const [agentPayload, setAgentPayload] = useState({
    firstname: '', phone: '', lastname: '', email: '',
  });

  const [inputErrors, setInputErrors] = useState({
    firstname: '', username: '', phone: '', lastname: '', email: '',
  });

  const agentUsername = agentPayload.firstname && agentPayload.lastname ? `${agentPayload.firstname}.${agentPayload.lastname}`.toLowerCase() : '';

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;

    setAgentPayload(state => ({ ...state, [name]: value }));
    setInputErrors(state => ({ ...state, [name]: '' }));
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    const {
      error,
      value,
    } = agentValidationSchema.validate({
      ...agentPayload,
      username: agentUsername,
    },
    { abortEarly: false });

    if (!error) {
      addAgent(value);
    }

    if (error && error.isJoi) {
      const { details } = error;
      const errorsEntries = details.map(({ message, path }) => [path[0], message]);
      setInputErrors(Object.fromEntries(errorsEntries));
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header} />
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>Add new agent</Typography>
      </div>
      <div className={classes.content}>
        <div>
          <form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  onChange={handleChange}
                  value={agentPayload.firstname}
                  error={Boolean(inputErrors.firstname)}
                  helperText={inputErrors.firstname}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  onChange={handleChange}
                  value={agentPayload.lastname}
                  error={Boolean(inputErrors.lastname)}
                  helperText={inputErrors.lastname}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={agentPayload.email}
                  error={Boolean(inputErrors.email)}
                  helperText={inputErrors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="filled"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  value={agentUsername}
                  error={Boolean(inputErrors.username)}
                  helperText={inputErrors.username}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="999-999-9999"
                  onChange={handleChange}
                  value={agentPayload.phone}
                >
                  {(inputProps: InputState) => (
                    <TextField
                      {...inputProps}
                      variant="filled"
                      type="tel"
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="Phone"
                      name="phone"
                      error={Boolean(inputErrors.phone)}
                      helperText={inputErrors.phone}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>

            <div className={classes.cardActions}>
              <Grid container alignItems="center">
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={loading}
                  >
                    Save
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AgentForm);

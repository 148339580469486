export const CATALOG_REQUEST = 'CATALOG_REQUEST';
export const CATALOG_REQUEST_SUCCESS = 'CATALOG_REQUEST_SUCCESS';
export const CATALOG_REQUEST_FAILED = 'CATALOG_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type CatalogEntity = {
  catalog_id: number;
  catalog_type_id: number;
  name: string;
  value: string;
};

export type CatalogRequestSuccess = Array<CatalogEntity>;

export type CatalogState = {
  items: Array<CatalogEntity>;
  loading: boolean;
  error: ErrorMessage['message'];
};

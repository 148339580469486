import {
  RETRIEVE_INVOICES,
  BILLING_REQUEST,
  BILLING_REQUEST_SUCCESS,
  BILLING_REQUEST_FAILED,
  INVOICE_DETAILS,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAILED,
  ErrorMessage,
  Invoice,
} from '../types/billing';
import { BillingActionsTypes } from '../actions/billing';

export type BillingStateType = {
  invoices: Array<Invoice>,
  loading: boolean,
  selected: Invoice | null,
  error: ErrorMessage['message'],
  total: number,
};

const initialBillingState:BillingStateType = {
  invoices: [],
  loading: false,
  selected: null,
  error: '',
  total: 0,
};

export default function BillingReducer(
  state = initialBillingState,
  action: BillingActionsTypes,
): BillingStateType {
  switch (action.type) {
    case RETRIEVE_INVOICES: {
      return {
        ...state,
      };
    }

    case BILLING_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case BILLING_REQUEST_SUCCESS: {
      const { invoices, total } = action.payload;

      return {
        ...state,
        loading: false,
        invoices,
        total,
      };
    }

    case BILLING_REQUEST_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        invoices: [],
      };
    }

    case INVOICE_DETAILS: {
      return {
        ...state,
        loading: true,
      };
    }

    case INVOICE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
      };
    }

    case INVOICE_DETAILS_FAILED: {
      return {
        ...state,
        selected: null,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

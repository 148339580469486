import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  logo: {
    height: 135,
    position: 'absolute',
    left: '50%',
    top: '10%',
    transform: 'translate(-50%, -50%)',
  },
  welcomeMessage: {
    fontSize: 30,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'oblique',
  },
  blurb: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translate(-50%, -50%)',
    fontSize: 40,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'oblique',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { WithStyles } from '@material-ui/styles';
import { Redirect, Link, RouteComponentProps } from 'react-router-dom';

import { isUserAuthorized } from '../../services/auth';
import { validateTokenRequest, passwordUpdateRequest } from '../../services/reset-password';
import styles from './styles';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const ResetPassword: React.FC<Props> = ({ classes, location, history }) => {
  const [mounted, setMounted] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({ password: '', confirm: '' });
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const token = new URLSearchParams(location.search).get('token');
  const isAuthenticated = isUserAuthorized();
  const { push } = history;

  useEffect(() => {
    function validateResetToken(resetToken: string) {
      return validateTokenRequest(resetToken);
    }

    validateResetToken(token!)
      .then(() => {
        if (mounted) {
          setValidating(false);
        }
      })
      .catch(() => {
        push('/login');
      });

    return () => setMounted(false);
  }, [mounted, token, push]);

  const error = Boolean(errorMessage);
  const disabled = !formData.password || !formData.confirm || loading || error;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.currentTarget;

    setFormData(prevState => ({ ...prevState, [name]: value }));

    if (errorMessage) {
      setErrorMessage(null);
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (formData.password !== formData.confirm) {
      setErrorMessage('Password doesn\'t match.');
    } else {
      setLoading(true);
      passwordUpdateRequest({ token, password: formData.password })
        .then(res => {
          if (mounted && res.status === 200) {
            setOpen(true);
          }
        })
        .catch(err => {
          const { response } = err;
          if (mounted) {
            const codes = [400, 404, 500];
            const message = response && codes.includes(response.status) ? response.data : 'Password doesn\'t changed, please try later.';

            setErrorMessage(message);
          }
        }).finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    }
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (validating) {
    return (
      <CircularProgress
        size={48}
        className={classnames(classes.progress, classes.progressContainer)}
      />
    );
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="row"
      className={classes.container}
    >
      <form
        noValidate
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Typography align="center" variant="h4">
            Enter new password
        </Typography>
        <TextField
          error={error}
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
        />
        <TextField
          error={error}
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="confirm"
          label="Confirm password"
          name="confirm"
          type="password"
          value={formData.confirm}
          onChange={handleChange}
          helperText={errorMessage}
        />
        <Grid container justify="flex-end" alignItems="center">
          <div className={classes.progressButtonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                className={classnames(classes.progress, classes.progressButton)}
              />
            )}
          </div>
        </Grid>
      </form>
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password changed successfully, please login to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/login">
            Sign In.
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ResetPassword;

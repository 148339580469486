import {
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_REQUEST_FAILED,
  AGENT_DETAILS_REQUEST_SUCCESS,
  AGENTS_REQUEST,
  AGENTS_REQUEST_SUCCESS,
  AGENTS_REQUEST_FAILED,
  ADD_AGENT_REQUEST,
  ADD_AGENT_REQUEST_SUCCESS,
  ADD_AGENT_REQUEST_FAILED,
  UPDATE_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST_SUCCESS,
  UPDATE_AGENT_REQUEST_FAILED,
  AgentsRequestSuccess,
  AgentDetailsRequestSuccess,
  ErrorMessage,
} from '../types/agents';
import { Query } from '../../utils/query';

export const agentsRequest = (query?: Query) => ({
  type: AGENTS_REQUEST,
  payload: query,
} as const);

export const agentsRequestFailed = (payload: ErrorMessage) => ({
  type: AGENTS_REQUEST_FAILED,
  payload,
} as const);

export const agentsRequestSuccess = (payload: AgentsRequestSuccess) => ({
  type: AGENTS_REQUEST_SUCCESS,
  payload,
} as const);

export const agentDetailsRequest = (agentID: number | string) => ({
  type: AGENT_DETAILS_REQUEST,
  payload: agentID,
} as const);

export const agentDetailsFailed = (payload: ErrorMessage) => ({
  type: AGENT_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const agentDetailsSuccess = (payload: AgentDetailsRequestSuccess) => ({
  type: AGENT_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const addAgentRequest = (agentPayload: Record<string, any>) => ({
  type: ADD_AGENT_REQUEST,
  payload: agentPayload,
} as const);

export const addAgentFailed = (payload: ErrorMessage) => ({
  type: ADD_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const addAgentSuccess = () => ({
  type: ADD_AGENT_REQUEST_SUCCESS,
} as const);

export const updateAgentRequest = (agentPayload: Record<string, any>) => ({
  type: UPDATE_AGENT_REQUEST,
  payload: agentPayload,
} as const);

export const updateAgentFailed = (payload: ErrorMessage) => ({
  type: UPDATE_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const updateAgentSuccess = () => ({
  type: UPDATE_AGENT_REQUEST_SUCCESS,
} as const);

export type AgentsActionTypes =
  | ReturnType<typeof agentsRequest>
  | ReturnType<typeof agentsRequestFailed>
  | ReturnType<typeof agentsRequestSuccess>
  | ReturnType<typeof agentDetailsRequest>
  | ReturnType<typeof agentDetailsFailed>
  | ReturnType<typeof agentDetailsSuccess>
  | ReturnType<typeof addAgentRequest>
  | ReturnType<typeof addAgentFailed>
  | ReturnType<typeof addAgentSuccess>
  | ReturnType<typeof updateAgentRequest>
  | ReturnType<typeof updateAgentFailed>
  | ReturnType<typeof updateAgentSuccess>;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Avatar from '@material-ui/core/Avatar';
import { WithStyles } from '@material-ui/styles';
import StatusBadge from '../../../components/StatusBadge';
import TypeBadge from '../../../components/TypeBadge';

import styles from './styles';

type Props = {
  onItemClick: (customerId: string | number) => void;
  onMoreButtonClick: (customerId: string | number) => void;
  firstName: string;
  lastName: string;
  email?: string;
  cell: string;
  type: string;
  status: string;
  customerId: string | number;
} & WithStyles<typeof styles>;

function getAvatarContent(
  firstname: string = '',
  lastname: string = '',
): string {
  return `${firstname.slice(0, 1)} ${lastname.slice(0, 1)}`;
}


const ListItem: React.FC<Props> = props => {
  const {
    classes,
    onItemClick,
    onMoreButtonClick,
    firstName,
    lastName,
    email = '',
    cell = '',
    type,
    status,
    customerId,
  } = props;

  const avatarContent = getAvatarContent(firstName, lastName);

  return (
    <div
      role="presentation"
      onClick={() => onItemClick(customerId)}
      className={classes.rootListItem}
    >
      <div className={classes.listContent}>
        <div className={classes.avatarWrapper}>
          <Avatar className={classes.avatar}>{avatarContent}</Avatar>
        </div>

        <div className={classes.userWrapper}>
          <div className={classes.user}>
            <div className={classes.userInfo}>
              <p className={classes.name}>{`${firstName} ${lastName}`}</p>
              <div className={classes.spacer} />
            </div>
            <div className={classes.userContacts}>
              <p className={classes.text}>{email}</p>
              <p className={classes.text}>{cell}</p>
            </div>
          </div>

          <div className={classes.activitiesWrapper}>
            <div className={classes.activity}>
              <TypeBadge type={type} />
            </div>
            <div className={classes.activity}>
              <StatusBadge status={status} />
            </div>
          </div>
        </div>

        <div className={classes.moreButtonWrapper}>
          <IconButton
            classes={{ root: classes.rootIconButton }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onMoreButtonClick(customerId);
            }}
          >
            <MoreIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ListItem);

import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CustomCard from '../../components/CustomCard';
import SimpleTable, { SimpleTableHeaderProp } from '../../components/SimpleTable/SimpleTable';
import CustomCardHeader from '../../components/CustomCardHeader/index';
import LineChart from '../../components/LineChart';
import Stats from '../Stats';

import { Visit } from '../../redux/types/visits';
import { Query } from '../../utils/query';
import { formatDate } from '../../utils/date';

type VisitsChartData = {
  labels: Array<string>;
  data: Array<number>;
};

type Props = {
  weeklyVisitsChartData: VisitsChartData;
  yearlyVisitsChartData: VisitsChartData;
  loadWeeklyVisits: () => void;
  loadYearlyVisits: () => void;
  loadRecentVisits: (query: Query) => void;
  recentVisits: Array<Visit>;
};

const visitsHeaders: SimpleTableHeaderProp<Visit> = [
  { key: 'firstname', label: 'First Name' },
  { key: 'lastname', label: 'Last Name' },
  { key: 'subdivision', label: 'Subdivision' },
  { key: 'visitor_status_id', label: 'Visitor Status' },
  { key: 'visitor_type_id', label: 'Visitor Type' },
  { key: 'modified_on', label: 'Date', date: true },
];

const Dashboard: React.FC<Props> = props => {
  const {
    weeklyVisitsChartData,
    yearlyVisitsChartData,
    loadWeeklyVisits,
    loadYearlyVisits,
    recentVisits,
    loadRecentVisits,
  } = props;

  const [updateTime, setUpdateTime] = useState<null| Date>(null);
  const updateTimeString = updateTime ? `Updated at ${formatDate(updateTime, 'HH:mm MM-dd-yyyy')}` : '';
  const visitsAsOf = updateTime ? `Visits as of ${formatDate(updateTime, 'MMMM dd, yyyy')}` : '';
  const dailyVisitsTitle = 'Daily Visits';
  const monthlyVisitsTitle = 'Monthly Visits';

  useEffect(() => {
    const query = { order: 'desc', orderBy: 'modified_on', limit: 5 };

    loadWeeklyVisits();
    loadYearlyVisits();
    loadRecentVisits(query);
    setUpdateTime(new Date());
  }, [loadWeeklyVisits, loadYearlyVisits, loadRecentVisits]);

  return (
    <>
      <div>
        <Stats />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <LineChart
            title={dailyVisitsTitle}
            subtitle={updateTimeString}
            chartData={weeklyVisitsChartData.data}
            chartLabels={weeklyVisitsChartData.labels}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LineChart
            title={monthlyVisitsTitle}
            subtitle={updateTimeString}
            chartData={yearlyVisitsChartData.data}
            chartLabels={yearlyVisitsChartData.labels}
          />
        </Grid>

        <Grid item sm={12} md={12}>
          <CustomCard>
            <CustomCardHeader
              title="My Recent Visits"
              subtitle={visitsAsOf}
              color="orange"
            />
            <SimpleTable items={recentVisits} header={visitsHeaders} />
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;

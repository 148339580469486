import React from 'react';
import useStyles from './styles';
import logo from '../../assets/menu.svg';


type Props = {
  message: string,
};

const LoginHeader: React.FC<Props> = props => {
  const { message } = props;
  const classes = useStyles();

  return (
    <div>
      <img className={classes.logo} src={logo} alt="logo" />
      <p className={classes.blurb}>{message}</p>
    </div>
  );
};

export default LoginHeader;

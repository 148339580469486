import React from 'react';
import {
  RouteComponentProps,
} from 'react-router-dom';
import Spinner from '@material-ui/core/CircularProgress';
import InvoicesTable from '../../components/InvoicesTable';
import BillingToolBar from '../BillingToolBar';
import { Invoice } from '../../redux/types/billing';
import TablePagination from '../../components/CustomTablePagination';
import { ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE } from '../../constants/table';
import useStyles from './styles';

type Props = {
  loading: boolean;
  invoicesDetails: Array<Invoice>;
  totalInvoiceAmount: number,
  downloadFakePdf: () => void,
} & RouteComponentProps;

const Invoices = (props: Props) => {
  const {
    invoicesDetails,
    totalInvoiceAmount,
    downloadFakePdf,
    history,
    match,
  } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE);
  const [page, setPage] = React.useState(0);

  function handleInvoiceSelect(invoice: Invoice) {
    history.push(`${match.url}/${invoice.id}`);
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  }

  return (
    <>
      {invoicesDetails.length ? (
        <>
          <BillingToolBar />
          <InvoicesTable
            invoices={invoicesDetails.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)}
            downloadFakePdf={downloadFakePdf}
            handleSingleInvoice={handleInvoiceSelect}
          />

          <TablePagination
            allItemsLoaded
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            rowsCount={invoicesDetails.length}
            rowsTotal={totalInvoiceAmount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default Invoices;

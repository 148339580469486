export const APP_INIT = 'APP_INIT';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_FAILED = 'APP_INIT_FAILED';

export type ErrorMessage = {
  message: string;
};

export type AppInitState = {
  initialized: boolean;
  error: ErrorMessage['message'];
};

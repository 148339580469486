import React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { WithStyles } from '@material-ui/styles';
import RowsPerPageSelect from './RowsPerPageSelect';
import PageNumbers from './PageNumbers';

import styles from './styles';

type Props = {
  onChangePage: (pageNumber: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  currentPage: number;
  rowsCount: number;
  rowsPerPage: number;
  rowsTotal: number;
  rowsPerPageOptions?: Array<number>;
  allItemsLoaded?: boolean
} & WithStyles<typeof styles>;

type LabelProps = {
  from: number;
  to: number;
  count: number;
};

const getLabelDisplayedRows = ({ from, to, count }: LabelProps): string => (count !== 0 ? `Showing ${from}-${to} of ${count}` : '');

const CustomTablePagination: React.FC<Props> = props => {
  const {
    onChangePage,
    currentPage,
    rowsCount,
    rowsTotal = 0,
    rowsPerPage,
    classes,
    rowsPerPageOptions,
    onChangeRowsPerPage,
    allItemsLoaded,
  } = props;

  const isOptionsProvided = rowsPerPageOptions && rowsPerPageOptions.length > 0;
  const uiPageNumber = currentPage + 1;
  const pageCount = Math.ceil(rowsTotal / rowsPerPage);

  const labelDisplayedRows = getLabelDisplayedRows({
    from: currentPage * rowsPerPage + 1,
    to: allItemsLoaded ? rowsTotal : currentPage * rowsPerPage + rowsCount,
    count: rowsTotal,
  });

  function handleChange(event: React.ChangeEvent<{ value: unknown }>): void {
    onChangeRowsPerPage(event.target.value as number);
  }

  return (
    <Toolbar>
      <Typography variant="caption" color="textSecondary">
        {labelDisplayedRows}
      </Typography>
      <div className={classes.pageIndexes}>
        <PageNumbers
          onPageClick={onChangePage}
          current={uiPageNumber}
          total={pageCount}
        />
      </div>
      {isOptionsProvided && (
        <div>
          Rows per page:

          <Select
            name="RowsPerPageSelect"
            value={rowsPerPage}
            onChange={handleChange}
            input={<RowsPerPageSelect />}
          >
            {rowsPerPageOptions!.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className={classes.paginationActions}>
        <Button
          aria-label="Previous Page"
          disabled={uiPageNumber === 1}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <ArrowLeft className={classes.actionIcon} />
          Prev
        </Button>
        <Button
          aria-label="Next Page"
          disabled={uiPageNumber >= pageCount}
          onClick={() => onChangePage(currentPage + 1)}
        >
          Next
          <ArrowRight className={classes.actionIcon} />
        </Button>
      </div>
    </Toolbar>
  );
};

export default React.memo(CustomTablePagination);

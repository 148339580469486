import Joi from '@hapi/joi';

const username = Joi.string()
  .label('User name')
  .regex(/^\w+\.\w+$/, 'required')
  .min(1)
  .max(250)
  .required();

const firstname = Joi.string()
  .label('First name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

const lastname = Joi.string()
  .label('Last name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

const email = Joi.string()
  .label('Email')
  .email({ minDomainSegments: 2, tlds: false })
  .required();

const phone = Joi.string()
  .label('Phone')
  .regex(/^\d{3}-\d{3}-\d{4}$/, 'required')
  .required();

const password = Joi.string()
  .label('Password')
  .allow('');

export default Joi.object({
  username,
  firstname,
  lastname,
  phone,
  email,
  password,
});

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  propertyDetailsRequest,
  propertyAddAgentRequest,
  propertyRemoveAgentRequest,
} from '../../redux/actions/properties';
import {
  selectPropertySelected,
  selectPropertiesRequestStatus,
  selectPropertiesUpdateStatus,
} from '../../redux/selectors/properties';
import { selectAgents, selectAgentsRequestStatus } from '../../redux/selectors/agents';
import PropertyDetails from './PropertyDetails';
import styles from './styles';
import { AppState } from '../../redux/store';
import { agentsRequest } from '../../redux/actions/agents';
import { selectUserData } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  propertyDetails: selectPropertySelected(state),
  loading: selectPropertiesRequestStatus(state),
  updatingProperties: selectPropertiesUpdateStatus(state),
  agents: selectAgents(state),
  loadingAgents: selectAgentsRequestStatus(state),
  isPM: selectUserData(state).isPM,
});

const mapDispatchToProps = {
  loadPropertyDetails: propertyDetailsRequest,
  loadAgents: agentsRequest,
  addAgent: propertyAddAgentRequest,
  removeAgent: propertyRemoveAgentRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PropertyDetails),
);

import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { LOCKS_REQUEST, LOCK_DETAILS_REQUEST } from '../types/locks';

import { loadLocks, loadLockDetails } from '../../services/locks';
import {
  locksRequestSuccess,
  locksRequestFailed,
  lockDetailsRequestFailed,
  lockDetailsRequestSuccess,
  LocksRequestActionType,
  LockDetailsRequestActionType,
} from '../actions/locks';

function* loadLocksSaga(action: LocksRequestActionType) {
  try {
    const { data } = yield call(loadLocks, action.payload);

    yield put(locksRequestSuccess(data));
  } catch (err) {
    yield put(locksRequestFailed(err));
  }
}

function* loadLockDetailsSaga(action: LockDetailsRequestActionType) {
  try {
    const { data } = yield call(loadLockDetails, action.payload);
    yield put(lockDetailsRequestSuccess(data));
  } catch (err) {
    yield put(lockDetailsRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(LOCKS_REQUEST, loadLocksSaga),
  takeLatest<any>(LOCK_DETAILS_REQUEST, loadLockDetailsSaga),
]);

import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  drawerContent: {
    padding: '30px 40px 30px 30px',
  },
  iconClose: {
    padding: 3,
    backgroundColor: '#8E8B99',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'none',
      color: theme.appPalette.orange,
    },
  },
  row: {
    marginBottom: 16,
  },
  formTitle: {
    marginBottom: 22,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  cardActions: {
    marginTop: 45,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '10px 16px',
    textTransform: 'capitalize',
    fontSize: 16,
    lineHeight: '20px',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  saveButton: {
    padding: '10px 40px',
  },
});

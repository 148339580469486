import {
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  AUTH_USER_REQUEST,
  AUTH_USER_LOGOUT,
  GET_OAUTH_CLIENTS_REQUEST,
  GET_OAUTH_CLIENTS_ERROR,
  GET_OAUTH_CLIENTS_SUCCESS,
  AuthRequest,
  ErrorMessage,
  AuthSuccess,
  OauthClient,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_ERROR,
  REMOVE_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_REQUEST,
  CREATE_OAUTH_CLIENTS_ERROR,
  CREATE_OAUTH_CLIENTS_SUCCESS,
} from '../types/auth';

export const authUserRequest = (payload: AuthRequest) => ({
  type: AUTH_USER_REQUEST,
  ...payload,
}) as const;

export const authUserError = (payload: ErrorMessage) => ({
  type: AUTH_USER_ERROR,
  payload,
}) as const;

export const authUserSuccess = (payload: AuthSuccess) => ({
  type: AUTH_USER_SUCCESS,
  payload,
}) as const;

export const authUserLogout = () => ({
  type: AUTH_USER_LOGOUT,
  payload: undefined,
}) as const;

export const getOauthClientsRequest = () => ({
  type: GET_OAUTH_CLIENTS_REQUEST,
}) as const;

export const getOauthClientsError = (payload: ErrorMessage) => ({
  type: GET_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const getOauthClientsSuccess = (payload: ApiResponse<OauthClient>) => ({
  type: GET_OAUTH_CLIENTS_SUCCESS,
  payload,
}) as const;

export const removeOauthClientsRequest = (id: number) => ({
  type: REMOVE_OAUTH_CLIENTS_REQUEST,
  payload: id,
}) as const;

export const removeOauthClientsError = (payload: ErrorMessage) => ({
  type: REMOVE_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const removeOauthClientsSuccess = (id: number) => ({
  type: REMOVE_OAUTH_CLIENTS_SUCCESS,
  payload: id,
}) as const;

export const createOauthClientsRequest = () => ({
  type: CREATE_OAUTH_CLIENTS_REQUEST,
}) as const;

export const createOauthClientsError = (payload: ErrorMessage) => ({
  type: CREATE_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const createOauthClientsSuccess = (payload: OauthClient) => ({
  type: CREATE_OAUTH_CLIENTS_SUCCESS,
  payload,
}) as const;

export type AuthActionsTypes = ReturnType<
typeof authUserRequest
| typeof authUserError
| typeof authUserSuccess
| typeof authUserLogout
| typeof getOauthClientsRequest
| typeof getOauthClientsError
| typeof getOauthClientsSuccess
| typeof removeOauthClientsRequest
| typeof removeOauthClientsError
| typeof removeOauthClientsSuccess
| typeof createOauthClientsRequest
| typeof createOauthClientsError
| typeof createOauthClientsSuccess
>;

import {
  AGENTS_REQUEST,
  AGENTS_REQUEST_SUCCESS,
  AGENTS_REQUEST_FAILED,
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_REQUEST_SUCCESS,
  AGENT_DETAILS_REQUEST_FAILED,
  ADD_AGENT_REQUEST,
  ADD_AGENT_REQUEST_FAILED,
  ADD_AGENT_REQUEST_SUCCESS,
  UPDATE_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST_FAILED,
  UPDATE_AGENT_REQUEST_SUCCESS,
  AgentsState,
} from '../types/agents';
import { AgentsActionTypes } from '../actions/agents';

const initialState: AgentsState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  selected: null,
};

export default function AgentReducer(
  state = initialState,
  action: AgentsActionTypes,
): AgentsState {
  switch (action.type) {
    case AGENTS_REQUEST:
    case ADD_AGENT_REQUEST:
    case UPDATE_AGENT_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case ADD_AGENT_REQUEST_SUCCESS:
    case UPDATE_AGENT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AGENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case AGENTS_REQUEST_FAILED:
    case AGENT_DETAILS_REQUEST_FAILED:
    case ADD_AGENT_REQUEST_FAILED:
    case UPDATE_AGENT_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case AGENT_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case AGENT_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

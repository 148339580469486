import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  propertiesRequest,
  propertiesExportRequest,
  propertiesExportAndPrintRequest,
} from '../../redux/actions/properties';
import {
  selectProperties,
  selectPropertiesRequestStatus,
  selectTotalProperties,
} from '../../redux/selectors/properties';
import Properties from './Properties';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  properties: selectProperties(state),
  loading: selectPropertiesRequestStatus(state),
  propertiesTotal: selectTotalProperties(state),
});

const mapDispatchToProps = {
  loadProperties: propertiesRequest,
  exportProperties: propertiesExportRequest,
  printProperties: propertiesExportAndPrintRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Properties),
);

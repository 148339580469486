import {
  SPLIT_FEATURES_REQUEST_SUCCESS,
  SPLIT_FEATURES_REQUEST_FAILED,
  SplitState,
} from '../types/split';
import {
  SplitActions,
} from '../actions/split';

const initialState: SplitState = {
  loaded: false,
  features: {},
};

export default function SplitReducer(
  state = initialState,
  action: SplitActions,
): SplitState {
  switch (action.type) {
    case SPLIT_FEATURES_REQUEST_SUCCESS: {
      return {
        ...state,
        features: action.payload,
        loaded: true,
      };
    }

    case SPLIT_FEATURES_REQUEST_FAILED: {
      return {
        ...state,
        features: {},
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}

import React from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';

import styles from './styles';
import SearchBar from '../../components/SearchBar';
import IntegrationWidget from '../../components/IntegrationWidget';
import IntegrationButton from '../../components/IntegrationButton';
import LassoImage from '../../assets/img/lasso.png';
import smartTouchCrmImg from '../../assets/img/smart-touch-crm.png';
import salesForcePlatformImg from '../../assets/img/sales_force_platform.png';
import bildersDesignExperienceImg from '../../assets/img/builders-design-experience.png';
import atlastRTXImg from '../../assets/img/atlastRTX.png';

import PlusIcon from '../../components/Icons/PlusIcon';
import ConnectionIcon from '../../components/Icons/ConnectionIcon';
import DrawerAddIntegration from './DrawerAddIntegration';
import DrawerCreateApi from './DrawerCreateApi';

type Props = WithStyles<typeof styles>;
const searchBarPlaceholder = 'Search integrations';
const mock = [
  {
    imgSource: LassoImage,
    name: 'Lasso CRM',
    description:
      'The #1 CRM for New Home Sales Builders invest a lot of money on marketing and sales, but most struggle to consistently convert enough leads into home buyers.',
  },
  {
    imgSource: smartTouchCrmImg,
    name: 'Smart Touch CRM',
    description:
      ' SmartTouch® CRM was designed for any company that needs to more effectively manage its multi-touch sales process with smart home builder CRM tools...',
  },
  {
    imgSource: salesForcePlatformImg,
    name: 'Salesforce API',
    description: 'Generic Salesforce API to connect to your own CRM!',
  },
  {
    imgSource: bildersDesignExperienceImg,
    name: 'Builders Design Experience',
    description:
      'BDX is the leading digital marketing company for the home building industry and growing your business is our mission.',
  },
  {
    imgSource: atlastRTXImg,
    name: 'AtlastRTX',
    description:
      'Try the only chatbot solution that works with humans to create amazing experiences 24/7, in 100+ languages, over text, messaging and web chat.',
  },
];

const integrationApis = [
  {
    id: 'createApi',
    name: 'Create Custom API',
    description:
      'Please click this button to let our staff know that you are interested in building a custom API',
    icon: ConnectionIcon,
  },
  {
    id: 'addIntegration',
    name: 'Add Integration',
    description: 'Please click this button to add a new integration.',
    icon: PlusIcon,
  },
];

const Integrations: React.FC<Props> = props => {
  const { classes } = props;
  const [open, setOpen] = React.useState<null | string>(null);

  function handleOpen(apiId: string) {
    return () => setOpen(apiId);
  }

  function handleClose() {
    setOpen(null);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} />
        </Grid>
      </Grid>

      <div className={classes.widgetsWrapper}>
        <Grid container spacing={4}>
          {mock.map(integration => (
            <Grid key={integration.name} item xs={6} sm={3}>
              <IntegrationWidget
                name={integration.name}
                description={integration.description}
                imgSource={integration.imgSource}
              />
            </Grid>
          ))}
          {integrationApis.map(api => (
            <Grid key={api.id} item xs={6} sm={3}>
              <IntegrationButton
                name={api.name}
                description={api.description}
                icon={api.icon}
                onClick={handleOpen(api.id)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerRoot }}
        open={Boolean(open)}
        onClose={handleClose}
      >
        {open === 'createApi' && <DrawerCreateApi onClose={handleClose} />}
        {open === 'addIntegration' && (
          <DrawerAddIntegration onClose={handleClose} />
        )}
      </Drawer>
    </>
  );
};

export default React.memo(Integrations);

import React from 'react';
import { WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import styles from './styles';

type Props = WithStyles<typeof styles> & {
  imgSource: string;
  name: string;
  description: string;
};

const IntegrationWidget: React.FC<Props> = props => {
  const {
    classes, imgSource, name, description,
  } = props;

  return (
    <Card elevation={3} className={classes.card}>
      <CardContent>
        <div className={classes.switchWrapper}>
          <Switch />
        </div>

        <div className={classes.logoWrapper}>
          <img src={imgSource} alt="" className={classes.image} />
        </div>

        <Typography className={classes.apiName}>{name}</Typography>
        <Typography className={classes.apiDescription}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(IntegrationWidget);

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/core';
import LockIcon from '../Icons/LockIcon';
// import { navTo } from '../../browserHsitory';

import styles from './styles';
import { Lock } from '../../redux/types/locks';

type Props = {
  device: Lock;
} & WithStyles<typeof styles>;

const DeviceWidget: React.FC<Props> = props => {
  const { classes, device } = props;

  return (
    <Card
      role="presentation"
      className={classes.card}
      // onClick={() => navTo(`/inventory/${device.serial_number}`)}
    >
      <CardContent>
        <div className={classes.iconWrapper}>
          <LockIcon className={classes.icon} />
        </div>
        <Typography className={classes.primaryText}>
          Device ID:

          <span className="deviceId">{device.serial_number}</span>
        </Typography>
        <Typography className={classes.secondaryText}>
          Location:

          {`${device.house_num} ${device.address_1}`}
        </Typography>
        <Typography className={classes.secondaryText}>
          Property ID:

          {device.property_id}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(DeviceWidget);

import { Theme, createStyles } from '@material-ui/core';

const drawerWidth = 240;

export default (theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: 64,
      '&:hover .$drawerPaper, .$drawerPaper': {
        width: drawerWidth,
      },
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingTop: 40,
    transition: 'width 0.4s ease',
    [theme.breakpoints.down('md')]: {
      width: 64,
      '&:hover': {
        width: drawerWidth,
      },
    },
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      display: 'none',
    },
  },
  smallLogo: {
    display: 'none',
    width: 'auto',
    height: 'auto',
    fontSize: 'initial',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'block',
    },
  },
  menuItems: {
    marginTop: theme.spacing(2),
  },
});

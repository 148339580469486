import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  AGENTS_REQUEST,
  AGENT_DETAILS_REQUEST,
  ADD_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST,
} from '../types/agents';
import {
  agentsRequest,
  agentsRequestSuccess,
  agentsRequestFailed,
  agentDetailsRequest,
  agentDetailsSuccess,
  agentDetailsFailed,
  addAgentRequest,
  addAgentFailed,
  addAgentSuccess,
  updateAgentRequest,
  updateAgentSuccess,
  updateAgentFailed,
} from '../actions/agents';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';
import {
  loadAgents,
  loadAgentById,
  createAgent,
  updateAgentById,
} from '../../services/agents';
import { navTo } from '../../browserHsitory';

function* loadAgentsSaga(action: ReturnType<typeof agentsRequest>) {
  try {
    const { data } = yield call(loadAgents, action.payload);
    yield put(agentsRequestSuccess(data));
  } catch (err) {
    yield put(agentsRequestFailed(err));
  }
}

function* loadAgentDetails(action: ReturnType<typeof agentDetailsRequest>) {
  try {
    const { data } = yield call(loadAgentById, action.payload);
    yield put(agentDetailsSuccess(data));
  } catch (err) {
    yield put(agentDetailsFailed(err.message));
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

function* addAgent(action: ReturnType<typeof addAgentRequest>) {
  try {
    yield call(createAgent, action.payload);
    yield put(addAgentSuccess());
    yield put(createSuccessSnackBar('Agent added successfully'));
    navTo('/agents');
  } catch (err) {
    yield put(addAgentFailed(err.message));
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

function* updateAgent(action: ReturnType<typeof updateAgentRequest>) {
  try {
    const { username, ...agentData } = action.payload;

    yield call(updateAgentById, agentData, username);
    yield put(updateAgentSuccess());
    yield put(createSuccessSnackBar('Updated successfully'));
  } catch (err) {
    yield put(updateAgentFailed(err.message));
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

export default all([
  takeLatest(AGENTS_REQUEST, loadAgentsSaga),
  takeLatest(AGENT_DETAILS_REQUEST, loadAgentDetails),
  takeLatest(ADD_AGENT_REQUEST, addAgent),
  takeLatest(UPDATE_AGENT_REQUEST, updateAgent),
]);

/* eslint-disable @typescript-eslint/camelcase */
export const BILLING_REQUEST = 'BILLING_REQUEST';
export const RETRIEVE_INVOICES = 'RETRIEVE_INVOICES';
export const BILLING_REQUEST_SUCCESS = 'BILLING_REQUEST_SUCCESS';
export const BILLING_REQUEST_FAILED = 'BILLING_REQUEST_FAILED';
export const PAID_INVOICES = 'PAID_INVOICES';
export const DOWNLOAD_FAKE_PDF = 'DOWNLOAD_FAKE_PDF';
export const INVOICE_DETAILS = 'INVOICE_DETAILS';
export const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS';
export const INVOICE_DETAILS_FAILED = 'INVOICE_DETAILS_FAILED';

type Billing_Address = {
  first_name: string,
  last_name: string,
  company: string,
};

export type ErrorMessage = {
  message: string;
};

export type Subscription = {
  plan: {
    invoice_name: string,
    description: string,
  },
  subscription: {
    plan_unit_price: string,
    plan_amount: string,
    mrr: number
  }
};

export type Invoice = {
  id: string;
  amount_paid: number | string;
  total: number | string;
  amount_due: number;
  customer_id: string;
  status: string;
  date: string;
  credits_applied: number,
  billing_address: Billing_Address;
  due_date: number,
  net_term_days: string,
  subscriptions: Array<Subscription>,
  customer: {company: string,
    first_name: string,
    last_name: string,
    billing_address: {line1: string,
      line2: string,
      line3: string,
      city: string,
      state_code: string,
      zip: string}}
};

import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { WithStyles } from '@material-ui/core/styles';
import StatusBadge from '../StatusBadge';
import TypeBadge from '../TypeBadge';

import styles from './styles';
import { CustomerInfo } from '../../redux/types/customers';

type Props = {
  customerInfo: CustomerInfo;
} & WithStyles<typeof styles>;

function getInitials(firstname: string, lastname: string): string {
  return `${firstname[0]}${lastname[0]}`;
}

function getFullAddress({
  street_address: streetAddress,
  city,
  state,
}: CustomerInfo): string {
  return [streetAddress, city, state].filter(Boolean).join(', ');
}

const CustomerInfoCard: React.FC<Props> = props => {
  const { classes, customerInfo } = props;
  const visitorStatus = customerInfo.visitor_status_id_value || '';
  const visitorType = customerInfo.visitor_type_id_value || '';

  return (
    <div className={classes.content}>
      <div>
        <Avatar component="div" classes={{ root: classes.rootAvatar }}>
          {getInitials(customerInfo.firstname, customerInfo.lastname)}
        </Avatar>
      </div>
      <div className={classes.row}>
        <Typography className={classes.customerName}>
          {`${
            customerInfo.firstname
          } ${customerInfo.lastname}`}
        </Typography>
      </div>
      <div className={classnames(classes.row, classes.statusAndType)}>
        <div>
          <Typography color="textSecondary">Recent status</Typography>
          <StatusBadge status={visitorStatus} />
        </div>
        <div>
          <Typography color="textSecondary">Type</Typography>
          <TypeBadge type={visitorType} />
        </div>
      </div>
      <div className={classes.row}>
        <Divider />
      </div>
      <div className={classes.row}>
        <Typography className={classes.title}>Contact Information</Typography>
        <Grid spacing={4} container wrap="wrap">
          {customerInfo.email_1 && (
            <Grid item xs={12}>
              <Typography color="textSecondary">Email</Typography>
              <Typography className={classes.email} noWrap>
                {customerInfo.email_1}
              </Typography>
            </Grid>
          )}

          {customerInfo.cell_phone_1 && (
            <Grid item xs={12}>
              <Typography color="textSecondary">Phone</Typography>
              <Typography noWrap>{customerInfo.cell_phone_1}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography color="textSecondary">Address</Typography>
            <Typography noWrap>{getFullAddress(customerInfo)}</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(CustomerInfoCard);

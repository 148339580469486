import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    '& > div': {
      marginLeft: 5,
    },
  },

  userGreeting: {
    marginRight: 30,
  },

  spacer: {
    flexGrow: 1,
  },

  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  notificationIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});
